import React from "react";
import styles from "./main.cssmodule.scss";
import RideDetails from "containers/RideDetails/RideDetails.jsx";
import BookingValidation from "containers/BookingValidation/BookingValidation.jsx";
import BookingValidationResult from "containers/BookingValidation/BookingValidationResult";
import RideMap from "containers/RideMap/RideMap.jsx";
import { JopSessionDetails } from "components/organisms/JopSessionDetails/JopSessionDetails";
import { Paper } from "@material-ui/core";
import { injectIntl, useIntl } from "react-intl";
import { connect } from "react-redux";
import { useSessionDetails } from "components/SearchForm/JOP/hooks";

function SearchResultConfirmationView({ reservationInfo }) {
  const intl = useIntl();

  const sessionIdentifier = reservationInfo?.custom_fields.SESSION_IDENTIFIER;
  const sessionDetails = useSessionDetails(sessionIdentifier);

  return (
    <div className={styles.rideConfirm__container}>
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "searchResultConfirm.title" })}
      </h1>
      {/* modal below */}
      <h2 className="visuallyHidden">
        {intl.formatMessage({ id: "search.confirm.single_trip" })}
      </h2>
      <BookingValidationResult />

      <div className={styles.rideConfirm__wrapper}>
        <div className={styles.rideConfirm__details}>
          <RideDetails sessionDetails={sessionDetails} />
        </div>
        <Paper
          className="mapContainerInRow"
          //ACCESSIBILITY : remove from keyboard UX
          // does not work ??
          tabIndex={-1}
        >
          <RideMap />
        </Paper>
        <div className={styles.rideConfirm__validation}>
          <JopSessionDetails sessionDetails={sessionDetails} />
          <BookingValidation sessionDetails={sessionDetails} />
        </div>
      </div>
    </div>
  );
}

const mapState = (state) => {
  const datetime = state.search.selectedDatetime;
  const reservationInfo =
    datetime && state.search.responses[datetime].reservation_info;
  return { reservationInfo };
};

export default connect(mapState)(injectIntl(SearchResultConfirmationView));
