import dayjs from "dayjs";

import api from "api";
import { API_ERROR_CODES } from "@/api/constants";
import cookie from "lib/cookie.js";
import { settings } from "config/app";
import { snackMessage } from "containers/SnackBar/actions.js";
import { resetSearch } from "containers/SearchForm/actions.js";
import { isAdmin } from "lib/user/userRoles.js";
import { generateValidationCode } from "utils/validationCode";

export const ON_CHANGE = "@@impersonate/ON_CHANGE";
export const ON_USER_SELECTED = "@@impersonate/ON_USER_SELECTED";
export const SESSION_STARTED = "@@impersonate/SESSION_STARTED";
export const CLOSE_SESSION = "@@impersonate/CLOSE_SESSION";
export const OPEN_IMPERSONATE_DIALOG =
  "@@impersonatePMR/OPEN_IMPERSONATE_DIALOG";
export const CLOSE_IMPERSONATE_DIALOG =
  "@@impersonatePMR/CLOSE_IMPERSONATE_DIALOG";
export const REQUEST_IMPERSONATE = "@@impersonate/REQUEST_IMPERSONATE";
export const REQUEST_IMPERSONATE_ERROR =
  "@@impersonate/REQUEST_IMPERSONATE_ERROR";

export const onChange = (value) => ({
  type: ON_CHANGE,
  value,
});

export const onUserSelected = (user) => ({
  type: ON_USER_SELECTED,
  user,
});

export const sessionStarted = (isReferent) => ({
  type: SESSION_STARTED,
  isReferent,
});

/**
 * Start an impersonate session.
 * We set a cookie to keep track of the impersonated token
 * to survive in case the admin refreshes the browser.
 *
 * @param  object user
 * @return
 */
export const startImpersonateSession =
  (
    navigate,
    loadUserProfile,
    selectedTerritoryKey,
    user,
    isReferent,
    clearDiscounts,
  ) =>
  (dispatch) => {
    if (clearDiscounts) {
      clearDiscounts();
    }
    dispatch({ type: REQUEST_IMPERSONATE });
    dispatch(resetSearch());
    api
      .getCustomerToken(
        { customerId: user.id },
        { territory: selectedTerritoryKey },
      )
      .then((json) => {
        /**
         * Create a session cookie with the impersonate
         * token
         */
        cookie.set(settings.cookieKeys.impersonateCookie, json.token, 0);
        // needed before fetch and check profile
        dispatch(sessionStarted(isReferent));

        loadUserProfile(true).then(() => {
          loadUserProfile()
            .then(() => {
              dispatch(sessionStarted(isReferent));
              navigate("/search");
            })
            .catch((error) => {
              if (
                error.reason === API_ERROR_CODES.USER_SUSPENDED &&
                error.message
              ) {
                dispatch(
                  snackMessage("error", error.message, JSON.stringify(error)),
                );
                dispatch(
                  closeImpersonateSession(navigate, loadUserProfile, false),
                );
              } else {
                dispatch(
                  snackMessage(
                    "error",
                    _.get(error, "infos.detail.message", "unable to login"),
                    JSON.stringify(error), // this error is bad formatted ?
                  ),
                );
                dispatch(closeImpersonateSession(navigate, loadUserProfile));
              }
            });
        });
      })
      .catch((error) => {
        dispatch({ type: REQUEST_IMPERSONATE_ERROR });
        console.log(
          "== unable to retrieve token, user data must be uncoherent ==",
        );
        console.debug(error);
        navigate("/impersonate");
      });
  };

export const closeImpersonateSession =
  (navigate, loadUserProfile, showSnack = true) =>
  (dispatch, getState, getIntl) => {
    dispatch({ type: REQUEST_IMPERSONATE });
    cookie.erase(settings.cookieKeys.impersonateCookie);
    // Operator eXperience : we rest search between impersonated users
    dispatch(resetSearch());
    loadUserProfile(true).then((userProfile) => {
      dispatch({ type: CLOSE_SESSION });
      if (showSnack) {
        dispatch(
          snackMessage(
            "success",
            getIntl().formatMessage({ id: "impersonate.session_closed" }),
          ),
        );
      }

      // Redirect admin users to impersonate page and customers (referers, tpmr feature) to search page
      if (isAdmin(userProfile.groups || [])) navigate("/impersonate");
      else navigate("/search");
    });
  };

const getUser = (value, selectedTerritoryKey) =>
  new Promise((resolve, reject) => {
    api
      .getCustomerList(null, {
        limit: 1,
        offset: 0,
        search: encodeURIComponent(value.trim()),
        territory: selectedTerritoryKey,
      })
      .then((json) => {
        if (json.results.length === 1) {
          // New apiAxios endpoint sends user id in user attribute, previous one was sending it on id attribute.
          json.results[0].id = json.results[0].user;
          resolve(json.results[0]);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });

const nextToView = {
  booking: "search",
  trips: "reservation",
  account: "profile",
};

export const lookupImpersonate =
  (navigate, loadUserProfile, phone, next, selectedTerritoryKey) =>
  (dispatch) => {
    cookie.erase(settings.cookieKeys.impersonateCookie);

    if (!next && !phone) return navigate("/search");
    if (next === "booking" && !phone) return navigate("/search");

    // 1 we suppose that phone is on +33... shape ("+" is erased in URI)
    getUser(`+${phone}`, selectedTerritoryKey)
      .then((user) => {
        if (nextToView[next])
          dispatch(
            startImpersonateSession(
              navigate,
              loadUserProfile,
              selectedTerritoryKey,
              user,
              nextToView[next],
            ),
          );
        else navigate("/impersonate");
      })
      .catch(() => {
        // 2 if no result, we suppose that phone was 06... shape
        getUser(phone, selectedTerritoryKey)
          .then((user) => {
            if (nextToView[next])
              dispatch(
                startImpersonateSession(
                  navigate,
                  loadUserProfile,
                  selectedTerritoryKey,
                  user,
                  nextToView[next],
                ),
              );
            else navigate("/impersonate");
          })
          .catch(() => navigate("/impersonate"));
      });
  };

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const createImpersonateAndModifyUser =
  (navigate, loadUserProfile) => async (dispatch) => {
    const timestamp = dayjs(new Date()).format("X");
    const password = makeid(10);
    const email = `user_${timestamp}@padam.tmp`;
    const firstName = `User ${timestamp}`;
    const lastName = `User ${timestamp}`;
    const phoneNumber = `+3380${timestamp.substring(timestamp.length - 7)}`;

    const user = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      password: password,
      password_repeat: password,
      phone_number: phoneNumber,
      is_phone_validated: true,
      validation_code: await generateValidationCode(
        firstName,
        lastName,
        phoneNumber,
        email,
      ),
    };

    const createUserCallback = (json) => {
      cookie.set(settings.cookieKeys.impersonateCookie, json.token, 0);

      // Need session started before, to avoid phone validation view:
      dispatch(sessionStarted());

      loadUserProfile()
        .then(() => {
          navigate("/profile");
        })
        .catch((error) => {
          console.log(`Error while loading user profile: ${error}`);

          dispatch(closeImpersonateSession(navigate, loadUserProfile));
        });
    };

    api.createUser(user).then(createUserCallback);
  };

export const openImpersonateDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_IMPERSONATE_DIALOG,
  });
};

export const closeImpersonateDialog = () => ({
  type: CLOSE_IMPERSONATE_DIALOG,
});
