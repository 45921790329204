import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function BurgerMenu(props) {
  return (
    <SvgIcon {...props}>
      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
      </svg>
    </SvgIcon>
  );
}

export default BurgerMenu;
