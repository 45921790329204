import React, { useContext } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Card, Paper, Typography } from "@material-ui/core";

import { UserContext } from "contexts/UserContext";

import jopdata from "components/SearchForm/JOP/jopdata.json";
import { JopSessionDetails } from "components/SearchForm/JOP/hooks";

import styles from "./JopSessionDetails.cssmodule.scss";

export function JopSessionDetails({
  sessionDetails,
}: {
  sessionDetails: JopSessionDetails;
}) {
  const { language } = useContext(UserContext);

  if (sessionDetails === undefined) {
    return (
      <Paper>
        <Card className={styles.container}>
          <Typography>
            <FormattedMessage
              id="jop2024.session_details_not_found"
              defaultMessage="Les détails de la session n'ont pas été trouvés."
            />
          </Typography>
        </Card>
      </Paper>
    );
  }

  const { gamesType, disciplineCode, session } = sessionDetails;

  return (
    <Paper>
      <Card className={styles.container}>
        <Typography variant="h3">
          {gamesType === "paralympic" ? (
            <FormattedMessage
              id="jop2024.paralympic_games"
              defaultMessage="Jeux paralympiques"
            />
          ) : (
            <FormattedMessage
              id="jop2024.olympic_games"
              defaultMessage="Jeux olympiques"
            />
          )}
        </Typography>
        <Typography>
          {jopdata.disciplines[disciplineCode][language]} —{" "}
          {jopdata.sites[session.siteCode].label[language]}
        </Typography>
        <Typography>
          {session.code} ({session.startTime} - {session.endTime})
        </Typography>
      </Card>
    </Paper>
  );
}
