import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Button, CircularProgress, Paper, Typography } from "@material-ui/core";

import api from "api";

import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";

import {
  addProposal,
  getPersonalItems,
  initiateMultiDateRequest,
  initiateRequest,
  newRideResponse,
  resetSearchButPersonalItems,
} from "containers/SearchForm/actions";

import styles from "./JopSearchForm.cssmodule.scss";

import JopPassengersSection, { PassengersConfig } from "./JopPassengersSection";
import JopOdSection, { JopOd } from "./JopOdSection";
import JopSessionSection from "./JopSessionSection";
import { JopSession, PersonalItems } from "./JopTypes";
import dayjs from "dayjs";

const PERSONAL_ITEM_NAMES = [
  "manual",
  "electric",
  "standard_seat",
  "assistance_dog",
  "walker",
  "crutches",
  "cane",
  "white_cane",
];

export default function SearchForm() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const prefillReturnTrip = location.state?.prefillReturnTrip || false;
  const { selectedTerritory } = useContext(TerritoryContext);
  const { language } = useContext(UserContext);
  const [session, setSession] = useState<JopSession | undefined>(undefined);
  const [orderNumber, setOrderNumber] = useState("");
  const [od, setOd] = useState<JopOd | undefined>(undefined);
  const [passengersConfig, setPassengersConfig] = useState<
    PassengersConfig | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const formComplete = Boolean(session && od && passengersConfig);

  useEffect(() => {
    dispatch(getPersonalItems(selectedTerritory));
  }, [selectedTerritory, dispatch]);

  useEffect(() => {
    // Hide error message when form state changes
    setError(undefined);
  }, [session, od]);

  useEffect(() => {
    // Reset OD if session is undefined
    if (!session) {
      setOd(undefined);
    }
  }, [session]);

  const { personalItems } = useSelector((state: any) => ({
    personalItems: state?.search?.searchForm?.personalItems?.personalItems as
      | PersonalItems
      | undefined,
  }));

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!personalItems) {
      setError("Personal items have not been retrieved.");
      return;
    }

    if (!session || !od || !passengersConfig) return;

    setLoading(true);

    const day = dayjs(session.date);

    // Compute categories
    const categories = {};
    PERSONAL_ITEM_NAMES.forEach((personalItemName) => {
      personalItems[personalItemName].count = 0;
    });
    if (passengersConfig.useWheelchair) {
      personalItems[passengersConfig.wheelchairType].count = 1; // Set wheelchair type
    } else {
      personalItems["standard_seat"].count += 1; // Set standard seat for passenger not using wheelchairs
    }
    if (passengersConfig.escort) {
      personalItems["standard_seat"].count += 1; // Set standard seat for escort
    }
    if (passengersConfig.needMobilityAid) {
      passengersConfig.mobilityAids.forEach((mobilityAid) => {
        personalItems[mobilityAid].count = 1; // Set mobility aid
      });
    }

    Object.values(personalItems)
      .filter((personalItem) => personalItem.count > 0)
      .forEach((personalItem) => {
        // Loop over categories and accumulate required number of extra seats
        personalItem.categories.forEach((category) => {
          const count = category.nb_seats * personalItem.count;
          if (category.name in categories && count) {
            categories[category.name] += count;
          } else {
            categories[category.name] = count;
          }
        });
      });
    // add extra for categories, following the search endpoint specifications
    const extras = {};
    Object.keys(categories).forEach((categoryKey) => {
      if (categories[categoryKey]) {
        extras[`extras_${categoryKey}`] = {
          [`requested_${categoryKey}_seats`]: categories[categoryKey],
        };
      }
    });

    const payload = {
      departure_position: {
        address: od.origin.label[language],
        latitude: od.origin.latitude,
        longitude: od.origin.longitude,
      },
      destination_position: {
        address: od.destination.label[language],
        latitude: od.destination.latitude,
        longitude: od.destination.longitude,
      },
      time_restriction_type: od.timeRestrictionType,
      standard_seats: passengersConfig.escort ? 2 : 1,
      custom_fields: {
        SESSION_IDENTIFIER: session.code,
        ORDER_NUMBER: orderNumber,
      },
      datetime: od.datetime.format(),
      ...extras,
    };

    const formattedDay = day.format("YYYY/MM/DD");

    dispatch(resetSearchButPersonalItems());
    dispatch(initiateRequest());
    dispatch(initiateMultiDateRequest([formattedDay]));
    api
      .new_search(payload, { territory: selectedTerritory?.territory_key })
      .then((json) => {
        if (json.message) {
          setError(json.message);
          return;
        }

        const proposedDatetimes = json.reservation_info.proposed_datetimes;
        const firstPropositionId = proposedDatetimes[0].id;

        dispatch(newRideResponse(json, formattedDay));
        dispatch(addProposal(formattedDay, firstPropositionId));

        navigate("/search/result/confirmation");

        return;
      })
      .catch((error) => {
        setError(error.infos.detail.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Paper>
        <div className={styles.jopForm}>
          <JopSessionSection
            onSessionChange={setSession}
            onOrderNumberChange={setOrderNumber}
            prefillReturnTrip={prefillReturnTrip}
          />

          {session && orderNumber && (
            <JopOdSection
              session={session}
              onOdChange={setOd}
              prefillReturnTrip={prefillReturnTrip}
            />
          )}

          {od && (
            <JopPassengersSection
              onPassengersConfigChange={setPassengersConfig}
              prefillReturnTrip={prefillReturnTrip}
            />
          )}
        </div>

        {error && <div className={styles.errorMessage}>{error}</div>}

        <div className={styles.submitButton}>
          <Button
            type="submit"
            variant="contained"
            disabled={!formComplete || loading}
            color="primary"
            style={{ minWidth: "15rem" }}
            data-testid="searchform-submit-btn"
          >
            <Typography variant="body1" className={styles.submitButtonText}>
              {loading && <CircularProgress />}
              {intl.formatMessage({ id: "misc.validate" })}
            </Typography>
          </Button>
        </div>
      </Paper>
    </form>
  );
}
