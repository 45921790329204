import { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

const ROUTES = [
  // Unauthenticated user and signup after search disabled:
  "login",
  "sso-login",
  "oauth-callback",
  "forget-password-email",
  "reset-password",
  "unsubscribe",
  "stop-notifications",
  "register/verification",
  "register",
  "browser_incompatibility",
  "sitemap",

  // Authenticated user or signup after search enabled:
  "search/result/confirmation/payment",
  "search/result/confirmation",
  "search/result",
  "search",
  "profile",
  "reservation/:id/edit/confirm",
  "reservation/:id/edit",
  "reservation/:id",
  "reservation",
  "impersonate",
  "lookup_impersonate",
  "about",
  "accessibility",
];

/**
 * Custom hook to set the <title> of the page according to the pathname.
 * @param {string} brand
 */
export function usePathnameForTitle(brand) {
  const intl = useIntl();

  const pathname = useLocation().pathname;

  useEffect(() => {
    const currentRoute = ROUTES.find((route) => {
      return matchPath({ path: `/${route}` }, pathname);
    });

    let pageName;

    switch (currentRoute) {
      case "login":
        pageName = intl.formatMessage({ id: "signin.title" });
        break;

      case "sso-login":
        pageName = intl.formatMessage({ id: "signin.title" });
        break;

      case "oauth/callback":
        pageName = intl.formatMessage({ id: "signin.title" });
        break;

      case "forget-password-email":
        pageName = intl.formatMessage({ id: "forgetPassword.title" });
        break;

      case "reset-password":
        pageName = intl.formatMessage({ id: "forgetPassword.title" });
        break;

      case "unsubscribe":
        pageName = intl.formatMessage({ id: "title.unsubscribe" });
        break;

      case "stop-notifications":
        pageName = intl.formatMessage({
          id: "title.stop-notifications",
          defaultMessage: "Stop notifications",
        });
        break;

      case "register/verification":
        pageName = intl.formatMessage({ id: "title.phone_verification" });
        break;

      case "register":
        pageName = intl.formatMessage({ id: "title.register" });
        break;

      case "browser_incompatibility":
        pageName = intl.formatMessage({ id: "title.incompatible_browser" });
        break;

      case "sitemap":
        pageName = intl.formatMessage({ id: "title.sitemap" });
        break;

      case "search/result/confirmation/payment":
        pageName = intl.formatMessage({ id: "title.booking_payment" });
        break;

      case "search/result/confirmation":
        pageName = intl.formatMessage({ id: "title.booking_confirmation" });
        break;

      case "search/result":
        pageName = intl.formatMessage({ id: "title.search_result" });
        break;

      case "search":
      case "searchjop":
        pageName = intl.formatMessage({ id: "title.search" });
        break;

      case "profile":
        pageName = intl.formatMessage({ id: "title.profile" });
        break;

      case "reservation/:id/edit/confirm":
        pageName = intl.formatMessage({ id: "title.booking_edit_confirm" });
        break;

      case "reservation/:id/edit":
        pageName = intl.formatMessage({ id: "title.edit_booking" });
        break;

      case "reservation/:id":
        pageName = intl.formatMessage({ id: "title.booking" });
        break;

      case "reservation":
        pageName = intl.formatMessage({ id: "menu.journey" });
        break;

      case "impersonate":
        pageName = intl.formatMessage({ id: "title.impersonate" });
        break;

      case "lookup_impersonate":
        pageName = intl.formatMessage({ id: "title.lookup_impersonate" });
        break;

      case "about":
        pageName = intl.formatMessage({ id: "title.about" });
        break;

      case "accessibility":
        pageName = intl.formatMessage({
          id: "title.accessibility",
          defaultMessage: "Accessibility declaration",
        });
        break;

      default:
        pageName = intl.formatMessage({ id: "title.unknown" });
    }

    document.title = `${pageName} | ${brand}`;
  }, [brand, intl, pathname]);
}
