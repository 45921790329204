import React, { useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";
import dayjs from "dayjs";

import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@material-ui/core";

import { Alert } from "components/ui/alert";

import { ResultElement } from "organisms/ResultElement/ResultElement";
import ExpandLess from "components/Icons/ExpandLess";
import ExpandMore from "components/Icons/ExpandMore";
import { InfoIcon } from "lucide-react";

import {
  addFixedLineProposal,
  addProposal,
} from "containers/SearchForm/actions";

import { TerritoryContext } from "contexts/TerritoryContext";
import { ProductContext } from "contexts/ProductContext";

import { i18nVerboseDateFormat } from "utils/i18nDate";
import { capitalize } from "utils/strings";

import { useSearchResultRideStyles } from "./searchResultRideStyles";
import { FixedLinesResultElement } from "organisms/FixedLinesResultElement/FixedLinesResultElement";

function SearchResultRideContainer({
  addProposal,
  addFixedLineProposal,
  datetime,
  edition,
  isOpen,
  recurrenceId,
  reservation,
  ride,
  selectedProposals,
  toggle,
  formData,
  onlyFixedLines = false,
}) {
  //  TODO : we should use only the ride props from search.results, for datetime and recurring_offer etc
  //
  // TODO : we could isolate the folable card intelligence

  const { reservation_info, is_recurring_service, fixed_lines_results } = ride;

  const { selectedTerritory } = useContext(TerritoryContext);
  const { productParameters } = useContext(ProductContext);

  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const tabRef = useRef(null);
  const classes = useSearchResultRideStyles();
  let everyTimeRide = [];

  if (edition && !reservation) {
    return null;
  }

  const recurringOffer =
    selectedTerritory?.booking?.recurring_bookings?.enabled &&
    (selectedTerritory?.booking?.recurring_bookings?.offers || []).find(
      (o) => o.id === recurrenceId,
    );

  // triggered when clicked or keyboard selected
  const select = async (id) => {
    await addProposal(datetime, id);

    // in edition mode, keep it open
    if (!edition) {
      toggle(!isOpen);
    }
  };

  const selectFixedLine = async (datetime, proposal) => {
    await addFixedLineProposal(datetime, proposal);
    // in edition mode, keep it open
    if (!edition) {
      toggle(!isOpen);
    }
  };

  const proposal = reservation_info?.proposed_datetimes || [];

  if (fixed_lines_results) {
    everyTimeRide = everyTimeRide.concat(
      ...fixed_lines_results.map((trip) => {
        return {
          trip: trip,
          type: "fixed_line",
          datetime: trip.departure_datetime,
        };
      }),
    );
  }

  if (proposal.length > 0) {
    everyTimeRide = everyTimeRide.concat(
      ...proposal.map((elem) => {
        return {
          trip: elem,
          type: "timed_ride",
          datetime: elem.pickup_time,
        };
      }),
    );
  }

  everyTimeRide.sort((a, b) => {
    return dayjs(a.datetime).diff(dayjs(b.datetime));
  });

  const onKeyDown = (e) => {
    e.stopPropagation();
    switch (e.keyCode) {
      case 38: // UP ARROW KEY
        setHighlightedIndex(Math.max(0, highlightedIndex - 1));
        return;
      case 40: // DOWN ARROW KEY
        setHighlightedIndex(
          Math.min(proposal.length - 1, highlightedIndex + 1),
        );
        return;
      case 13: // ENTER KEY
        select(proposal[highlightedIndex].id);
        tabRef.current.focus();
        return;
      default:
        return;
    }
  };

  const datetimeId = `${datetime}-listbox`;

  if (edition && !reservation) {
    return null;
  }
  return (
    <Card
      className={
        selectedProposals && selectedProposals[datetime]
          ? classes.someRideSelected
          : classes.noRideSelected
      }
    >
      <CardActionArea
        className={classes.collapseHeader}
        ref={tabRef}
        aria-expanded={isOpen}
        onClick={() => toggle(!isOpen)}
      >
        <CardHeader
          className={classes.collapseHeaderText}
          id={datetimeId}
          title={
            is_recurring_service ? (
              <>
                <Typography variant="h2">{recurringOffer.name}</Typography>

                <Typography variant="h3">
                  <FormattedMessage
                    id="search.multi_date"
                    values={{
                      startDate: capitalize(
                        i18nVerboseDateFormat(ride.requested_datetime),
                      ),
                      endDate: capitalize(
                        i18nVerboseDateFormat(
                          ride.reservation_info.recurring_end_datetime,
                        ),
                      ),
                    }}
                  />
                </Typography>
              </>
            ) : (
              <Typography>
                {capitalize(i18nVerboseDateFormat(datetime))}
              </Typography>
            )
          }
        />

        <div className={classes.collapseIcon}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
      </CardActionArea>

      <Collapse in={isOpen}>
        <CardContent
          className={classes.proposalsList}
          id={datetime + "-propositions"}
          component="ul"
          role="listbox"
          aria-labelledby={datetimeId}
          aria-activedescendant={
            highlightedIndex >= 0
              ? "proposition-" + highlightedIndex
              : undefined
          }
          aria-expanded={isOpen}
          onKeyDown={(e) => onKeyDown(e)}
        >
          {onlyFixedLines && (
            <Alert variant="info" className="flex align-middle">
              <InfoIcon />
              <Typography variant="body1">
                <FormattedMessage
                  id="search.fixed_line.no_DRT.description"
                  values={{ brand: productParameters?.brand }}
                />
              </Typography>
            </Alert>
          )}
          {everyTimeRide &&
            everyTimeRide.map((elem, index) => {
              if (elem.type === "fixed_line") {
                return (
                  elem.trip.sections.length > 0 && (
                    <FixedLinesResultElement
                      trip={elem.trip}
                      index={index}
                      key={`fixed-lines-result-${index}`}
                      onClick={() => {
                        selectFixedLine(datetime, elem.trip);
                      }}
                    />
                  )
                );
              } else {
                return (
                  <ResultElement
                    booking={
                      !edition
                        ? elem.trip
                        : {
                            ...elem.trip,
                            pickup_node: reservation.pickup_node,
                            dropoff_node: reservation.dropoff_node,
                          }
                    }
                    index={index}
                    onClick={() => select(elem.trip.id)}
                    isAriaSelected={index === highlightedIndex} //hovered effect
                    isSelected={
                      selectedProposals &&
                      elem?.trip?.id === selectedProposals[datetime]
                    }
                    key={`result-${elem.trip.id}`}
                    hasDifferentNodes={{
                      pickup:
                        elem?.trip?.pickup_node?.id !==
                        formData?.departure?.nodeId,
                      dropoff:
                        elem?.trip?.dropoff_node?.id !==
                        formData?.destination?.nodeId,
                    }}
                  />
                );
              }
            })}
        </CardContent>
      </Collapse>
    </Card>
  );
}

const mapState = (state) => {
  return {
    selectedProposals: { ...state.search.selectedProposals },
    recurrenceId: state.search.searchForm.recurrence.id,
    reservation: state.reservation.reservation,
    formData: state.search.searchForm,
  };
};

const mapDispatch = (dispatch) => ({
  addProposal: (day, proposalIndex) => {
    dispatch(addProposal(day, proposalIndex));
  },

  addFixedLineProposal: (datetime, proposal) => {
    dispatch(addFixedLineProposal(datetime, proposal));
  },
});

export default connect(mapState, mapDispatch)(SearchResultRideContainer);
