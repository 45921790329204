import * as React from "react";
import { useIntl } from "react-intl";

import { Card, CardHeader } from "@material-ui/core";

import dayjs from "dayjs";

import { InfosBadges } from "molecules/InfosBadges/InfosBadges";
import { FixedLinesTimedRide } from "../FixedLinesTimeRide/FixedLinesTimedRide";

import { useFixedLinesResultElementStyles } from "./fixedLinesResultElementStyles";
import { FixedLinesTripTitle } from "molecules/FixedLinesTripTitle/FixedLinesTripTitle";
import { FIXED_LINES_MODE_WALK } from "organisms/FixedLinesResultElement/FixedLinesTypes";

export function FixedLinesResultElement({ trip, index, onClick }) {
  const classes = useFixedLinesResultElementStyles();
  const intl = useIntl();

  const pickupTime = dayjs
    .tz(
      trip.departure_datetime.substring(0, trip.departure_datetime.length - 5),
    )
    .format("HH:mm");
  const pickupNode = trip.sections[0].departure_stop.name;

  const dropoffTime = dayjs
    .tz(trip.arrival_datetime.substring(0, trip.arrival_datetime.length - 5))
    .format("HH:mm");
  const dropoffNode = trip.sections[trip.sections.length - 1].arrival_stop.name;

  const tripDuration = `${dayjs
    .duration({
      minutes: dayjs(trip.arrival_datetime).diff(
        dayjs(trip.departure_datetime),
        "minute",
      ),
    })
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min `;

  const totalWalkDuration =
    trip && trip.sections
      ? trip.sections
          .filter((section) => section.mode == FIXED_LINES_MODE_WALK)
          .reduce((acc, element) => acc + element.duration, 0)
      : 0;

  const walkDurationValue = dayjs.duration({
    minutes: totalWalkDuration / 60,
  });
  const walkDuration = `${walkDurationValue
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min`;

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <Card
      className={`${classes.card}`}
      id={"proposition-" + index}
      component="li"
      tabIndex={0}
      role="option"
      aria-label={`${intl.formatMessage(
        { id: "help.rideShort" },
        {
          pickupNode,
          pickupTime,
          dropoffNode,
          dropoffTime,
        },
      )} ${tripDuration}`}
      aria-selected={true}
      onClick={onClick}
      onKeyPress={(e) => handleKeyPress(e)}
    >
      <CardHeader
        title={<FixedLinesTripTitle trip={trip} hasTitle />}
      ></CardHeader>
      <FixedLinesTimedRide
        dropoffNodeName={dropoffNode}
        dropoffTime={dropoffTime}
        pickupNodeName={pickupNode}
        pickupTime={pickupTime}
      />

      <div className={classes.badgesContainer}>
        <InfosBadges
          duration={tripDuration}
          bikeSeats={0}
          hasBeenCanceledByDriver={false}
          standardSeats={0}
          personalItems={0}
          price={0}
          wheelchairSeats={0}
        />
      </div>
    </Card>
  );
}
