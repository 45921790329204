import React from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import JopSearchForm from "components/SearchForm/JOP/JopSearchForm";
import TermsDialogContainer from "containers/Terms/Terms.jsx";

import { useSearchViewStyles } from "./searchViewStyles";
import { Typography } from "@material-ui/core";

export default function SearchView() {
  const intl = useIntl();
  const classes = useSearchViewStyles();

  return (
    <>
      <div className={classes.searchViewContainer}>
        <Typography variant="h1" className={classes.heading}>
          <FormattedMessage
            id="jop2024.book_your_shuttle"
            defaultMessage="Réservez votre navette pour les Jeux Olympiques et Paralympiques de Paris 2024"
          />
        </Typography>
        <img
          width="1178"
          height="338"
          src="https://s3-eu-west-1.amazonaws.com/dev-bookingzone/jop2024/banniere-jop.png"
          alt={intl.formatMessage({
            id: "jop2024.banner",
            defaultMessage:
              "ïle de France Mobilités, partenaire officiel des Jeux Olympiques et Paralympiques de Paris 2024",
          })}
          className={classes.banniereJop}
        />
        <JopSearchForm />
      </div>

      <TermsDialogContainer />
    </>
  );
}
