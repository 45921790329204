import dayjs from "dayjs";

import {
  ON_FIELD_CHANGE,
  CHANGE_PASSENGERS,
  CHANGE_PERSONAL_ITEMS,
  PERSONAL_ITEMS_LOADING,
  CHANGE_INDICATIONS_TO_DRIVER,
  CLEAR_INDICATIONS_TO_DRIVER,
  ON_ADDRESS_SELECTED,
  NEW_RIDE_RESPONSE,
  NO_RIDE_RESPONSE,
  INITIATE_MULTI_DATE_REQUEST,
  INITIATE_REQUEST,
  END_REQUEST,
  ADD_PROPOSAL,
  REMOVE_PROPOSAL,
  SWITCH_SELECTED_BOOKING,
  ON_DAY_REMOVED,
  SUBMITTING_FORM,
  FORM_ERRORS,
  RESET_SEARCH,
  RESET_SEARCH_BUT_PERSONAL_ITEMS,
  RESET_SEARCH_FORM,
  ON_ADDRESS_SWAP,
  UPDATE_COORDS,
  ACTUALIZE_DEPARTURE,
  LAST_BOOKINGS_FETCHED,
  RESET_PASSENGERS_COUNT,
  FIXED_LINES_RESPONSE,
  ADD_FIXED_LINE_PROPOSAL,
  REMOVE_FIXED_LINE_PROPOSAL,
  INITIATE_REQUEST_FIXED_LINES,
  END_REQUEST_FIXED_LINES,
  RECEIVE_NODES,
  INITIATE_NODES_REQUEST,
  NODES_REQUEST_ERROR,
  SET_BOOKING_RETURN_TRIP,
} from "./actions.js";

import { ON_LOOKUP_PROPOSITION_RECEIVE } from "containers/LookupProposition/actions";

const initialState = {
  lastBookings: null,
  searchForm: {
    asap: true,
    departure: {
      display: "",
      nodeId: null,
      latitude: null,
      longitude: null,
      address: "",
      coordsUpdated: false,
      placeId: null,
    },
    destination: {
      display: "",
      nodeId: null,
      latitude: null,
      longitude: null,
      address: "",
      coordsUpdated: false,
      placeId: null,
    },
    time: null,
    timeRestrictionType: "departure",
    passengers: {
      standard: 1,
    },
    selectedDays: [],
    recurrence: {
      id: 0, // "don't repeat"
      start_datetime: null,
      end_datetime: null,
    },
    customFields: {},
    personalItems: {
      count: 0,
      isLoading: false,
      personalItems: {},
    },
    indicationsToDriver: "",
  },
  formErrors: {
    pickup: "",
    dropoff: "",
    time: "",
    selectedDays: "",
    recurrence: {
      start_datetime: "",
      end_datetime: "",
    },
    //
  },
  isRequesting: false,
  isRequestingFixedLines: false,
  daysRequesting: {},
  responses: {},
  requestErrors: {},
  selectedProposals: {},
  selectedDatetime: null,
  nodes: [],
  isRequestingNodes: false,
  isReturnTrip: false,
};

export const search = (state = initialState, action) => {
  switch (action.type) {
    case ON_DAY_REMOVED:
      delete state.selectedProposals[action.lookupKey];
      if (state.selectedDays) delete state.selectedDays[action.lookupKey];

      delete state.responses[action.lookupKey];

      return {
        ...state,
        selectedProposals: state.selectedProposals,
        selectedDays: state.selectedDays,
        responses: state.responses,
      };
    case ADD_PROPOSAL:
      // auto-select the first proposal
      return {
        ...state,
        selectedProposals: {
          ...state.selectedProposals,
          [action.datetime]: action.proposalId,
        },
        selectedDatetime: state.selectedDatetime || action.datetime,
      };
    case ADD_FIXED_LINE_PROPOSAL:
      // auto-select the first proposal
      return {
        ...state,
        selectedProposals: {
          ...state.selectedProposals,
          [action.datetime]: action.proposal,
        },
        selectedDatetime: state.selectedDatetime || action.datetime,
      };

    case REMOVE_PROPOSAL:
      delete state.selectedProposals[action.datetime];
      return {
        ...state,
        selectedDatetime:
          // if selectedDatetime is removed, re-assign
          state.selectedDatetime === action.datetime
            ? Object.keys(state.selectedProposals)[0] || null
            : state.selectedDatetime,
      };
    case REMOVE_FIXED_LINE_PROPOSAL:
      delete state.selectedProposals[action.datetime];
      return {
        ...state,
        selectedDatetime:
          // if selectedDatetime is removed, re-assign
          state.selectedDatetime === action.datetime
            ? Object.keys(state.selectedProposals)[0] || null
            : state.selectedDatetime,
      };
    case SWITCH_SELECTED_BOOKING:
      return {
        ...state,
        selectedDatetime: action.datetime,
      };
    case NO_RIDE_RESPONSE:
      return {
        ...state,
        requestErrors: {
          ...state.requestErrors,
          [action.requestedDay]: {
            message: action.message,
            code: action.code,
            searchRequestId: action.searchRequestId,
          },
        },
        daysRequesting: {
          ...state.daysRequesting,
          [action.requestedDay]: false,
        },
      };
    case INITIATE_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case END_REQUEST:
      return {
        ...state,
        isRequesting: false,
      };
    case INITIATE_REQUEST_FIXED_LINES:
      return {
        ...state,
        isRequestingFixedLines: true,
      };
    case END_REQUEST_FIXED_LINES:
      return {
        ...state,
        isRequestingFixedLines: false,
      };
    case INITIATE_MULTI_DATE_REQUEST:
      return {
        ...state,
        daysRequesting: action.daysRequesting,
        isRequesting: true,
      };
    case LAST_BOOKINGS_FETCHED:
      return {
        ...state,
        lastBookings: action.bookings,
      };
    case FIXED_LINES_RESPONSE:
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.requestedDay]: {
            ...(state.responses[action.requestedDay] || {}),
            fixed_lines_results: action.response || [],
          },
        },
      };
    case NEW_RIDE_RESPONSE:
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.requestedDay]: {
            ...(state.responses[action.requestedDay] || {}),
            ...action.response,
          },
        },
        daysRequesting: {
          ...state.daysRequesting,
          [action.requestedDay]: false,
        },
      };
    case ON_FIELD_CHANGE:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          [action.field]: action.value,
        },
        formErrors: {
          ...state.formErrors,
          [action.field]: "",
        },
      };
    case ACTUALIZE_DEPARTURE:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          asap: true,
          time: action.time,
          timeRestrictionType: action.timeRestrictionType,
          recurrence: {
            ...state.searchForm.recurrence,
            id: action.recurringOfferId,
          },
          selectedDays: action.selectedDays,
        },
      };
    case RESET_PASSENGERS_COUNT:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          passengers: {
            ...initialState.searchForm.passengers,
          },
        },
      };
    case CHANGE_PASSENGERS:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          passengers: {
            ...state.searchForm.passengers,
            [action.passengerType]: action.value,
          },
        },
      };
    case CHANGE_PERSONAL_ITEMS:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          personalItems: {
            ...state.searchForm.personalItems,
            personalItems: action.personalItems,
            count: Object.values(action.personalItems).reduce(
              (prev, current) => {
                return current.count + prev;
              },
              0,
            ),
          },
        },
      };
    case PERSONAL_ITEMS_LOADING:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          personalItems: {
            ...state.searchForm.personalItems,
            isLoading: action.value,
          },
        },
      };
    case CHANGE_INDICATIONS_TO_DRIVER:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          indicationsToDriver: action.indicationsToDriver,
        },
      };
    case CLEAR_INDICATIONS_TO_DRIVER:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          indicationsToDriver: "",
        },
      };
    case ON_ADDRESS_SELECTED:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          [action.addressType]: {
            display: action.suggestion,
            latitude: action.latitude,
            longitude: action.longitude,
            address: action.address,
            coordsUpdated: false,
            nodeId: action.nodeId,
            placeId: action.placeId,
          },
        },
      };
    case UPDATE_COORDS:
      // eslint-disable-next-line no-case-declarations
      const newSearchForm = { ...state.searchForm };
      for (const i in action.typesToUpdate) {
        const type = action.typesToUpdate[i];
        const update = action.coordsUpdated[i];
        const current = newSearchForm[type];
        const address =
          _.findIndex(current.address, update.zipcode) === -1
            ? `${current.address} (${update.zipcode})`
            : current.address;

        newSearchForm[type] = {
          ...current, // address
          latitude: update.latitude,
          longitude: update.longitude,
          address: address,
          coordsUpdated: true,
        };
      }
      return {
        ...state,
        searchForm: newSearchForm,
      };
    case SUBMITTING_FORM:
      return {
        ...initialState,
        nodes: state.nodes,
        isReturnTrip: state.isReturnTrip,
        searchForm: state.searchForm,
      };
    case FORM_ERRORS:
      return {
        ...state,
        formErrors: { ...action.errors },
      };
    case ON_ADDRESS_SWAP:
      return {
        ...state,
        searchForm: {
          ...state.searchForm,
          departure: state.searchForm.destination,
          destination: state.searchForm.departure,
        },
      };
    case SET_BOOKING_RETURN_TRIP:
      return {
        ...state,
        isReturnTrip: action.value,
      };
    case RESET_SEARCH_FORM:
      return {
        ...state,
        searchForm: initialState.searchForm,
      };
    case RESET_SEARCH:
      return initialState;
    case RESET_SEARCH_BUT_PERSONAL_ITEMS:
      return {
        ...initialState,
        searchForm: {
          ...initialState.searchForm,
          personalItems: state.searchForm.personalItems,
        },
      };
    default:
      return state;
    // Nodes
    case INITIATE_NODES_REQUEST:
      return {
        ...state,
        isRequestingNodes: true,
      };
    case RECEIVE_NODES:
      return {
        ...state,
        nodes: action.nodes,
        isRequestingNodes: false,
      };
    case NODES_REQUEST_ERROR:
      return {
        ...state,
        nodes: [],
        isRequestingNodes: false,
      };
    case ON_LOOKUP_PROPOSITION_RECEIVE:
      return {
        ...state,
        searchForm: {
          asap: false,
          departure: {
            display: action.proposition.pickup_stop.name,
            nodeId: action.proposition.pickup_stop.pk,
            latitude: action.proposition.pickup_stop.latitude,
            longitude: action.proposition.pickup_stop.longitude,
            address: action.proposition.pickup_stop.address,
            coordsUpdated: false,
            placeId: null,
          },
          destination: {
            display: action.proposition.dropoff_stop.name,
            nodeId: action.proposition.dropoff_stop.pk,
            latitude: action.proposition.dropoff_stop.latitude,
            longitude: action.proposition.dropoff_stop.longitude,
            address: action.proposition.dropoff_stop.address,
            coordsUpdated: false,
            placeId: null,
          },
          time: dayjs(action.search_request.requested_datetime),
          timeRestrictionType: action.search_request.time_restriction_type,
          passengers: {
            standard: action.search_request.passengers_number,
          },
          selectedDays: [],
          recurrence: {
            id: 0, // "don't repeat"
            start_datetime: null,
            end_datetime: null,
          },
          customFields: {},
          personalItems: {
            count: 0,
            isLoading: false,
            personalItems: {},
          },
          indicationsToDriver: "",
        },
        formErrors: {
          pickup: "",
          dropoff: "",
          time: "",
          selectedDays: "",
          recurrence: {
            start_datetime: "",
            end_datetime: "",
          },
          //
        },
        isRequesting: false,
        isRequestingFixedLines: false,
        daysRequesting: {},
        responses: {
          [action.proposition.proposed_pickup_time]: {
            reservation_info: {
              departure_position: {
                address: action.proposition.pickup_stop.address,
                latitude: action.proposition.pickup_stop.latitude,
                longitude: action.proposition.pickup_stop.longitude,
              },
              destination_position: {
                address: action.proposition.dropoff_stop.address,
                latitude: action.proposition.dropoff_stop.latitude,
                longitude: action.proposition.dropoff_stop.longitude,
              },
              id: action.search_request.id,
              proposed_datetimes: [
                {
                  ...action.proposition,
                  pickup_time: action.proposition.proposed_pickup_time,
                  dropoff_time: action.proposition.proposed_dropoff_time,
                  pickup_node: {
                    ...action.proposition.pickup_stop,
                    position: {
                      latitude: action.proposition.pickup_stop.latitude,
                      longitude: action.proposition.pickup_stop.longitude,
                    },
                  },
                  dropoff_node: {
                    ...action.proposition.dropoff_stop,
                    position: {
                      latitude: action.proposition.dropoff_stop.latitude,
                      longitude: action.proposition.dropoff_stop.longitude,
                    },
                  },
                },
              ],
            },
          },
        },
        requestErrors: {},
        selectedProposals: {
          [action.proposition.proposed_pickup_time]: action.proposition.id,
        },
        selectedDatetime: [action.proposition.proposed_pickup_time],
        nodes: [],
        isRequestingNodes: false,
        isReturnTrip: false,
      };
  }
};
