import React, { useContext, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { updateReservation } from "./actions.js";
import { TerritoryContext } from "contexts/TerritoryContext";

import { RideDetails } from "components/RideDetails/RideDetails.jsx";
import { RideDetailsSimplified } from "components/organisms/RideDetailsSimplified/RideDetailsSimplified";
import { FixedLinesRideDetails } from "components/organisms/FixedLinesRideDetails/FixedLinesRideDetails";

/**
 * Ride Details container.
 * The RideDetails component represents a ride view
 */
/* has a props .reservation |OR| represents state.search.results[] */
function RideDetailsContainer(props) {
  const {
    reservation,
    reservationInfo,
    selectedBooking,
    updateReservation,
    sessionDetails,
  } = props;

  const { selectedTerritory } = useContext(TerritoryContext);

  useEffect(() => {
    if (reservation)
      updateReservation(reservation.id, selectedTerritory.territory_key);
  }, [reservation, selectedTerritory.territory_key, updateReservation]);

  // CASE 1 / 2: from single reservation view <= data source is props.booking
  if (reservation) {
    return (
      <RideDetails
        booking={reservation}
        reservationInfo={reservation}
        sessionDetails={sessionDetails}
      />
    );
  } else if (!selectedBooking) {
    return null;
  } else if (selectedBooking.sections) {
    return <FixedLinesRideDetails booking={selectedBooking} />;
  }
  // CASE 2 / 2: from search results view <= data source is .selectedDatetime < .selectedProposals < .responses
  // OR FROM EDITION CONFIRM VIEW
  return (
    <RideDetailsSimplified
      booking={selectedBooking}
      reservationInfo={reservationInfo}
      sessionDetails={sessionDetails}
    />
  );
}

const mapDispatch = (dispatch) => ({
  updateReservation: (id, selectedTerritoryKey) => {
    dispatch(updateReservation(id, selectedTerritoryKey));
  },
});

/**
 * Inject props. ownProps lets us use this container in both
 * search result / search confirmation page and the single reservation view
 *
 * @param  object state
 * @param  object ownProps
 * @return object
 */
const mapState = (state, ownProps) => {
  let selectedBooking;
  let reservationInfo;

  if (!ownProps.reservation) {
    // retrieve selectedBooking with responses, selectedProposals and selectedDatetime
    const datetime = state.search.selectedDatetime;
    reservationInfo =
      datetime && state.search.responses[datetime].reservation_info;
    const selectedProposal =
      datetime && state.search.selectedProposals[datetime];
    if (typeof selectedProposal === "number") {
      selectedBooking =
        datetime &&
        reservationInfo.proposed_datetimes.find(
          (b) => b.id === selectedProposal,
        );
    } else {
      selectedBooking = state.search.selectedProposals[datetime];
    }
  }

  if (ownProps.edition) {
    const reservation = state.reservation.reservation;
    selectedBooking = {
      ...selectedBooking,
      departure_position: reservation.departure_position,
      destination_position: reservation.destination_position,
      pickup_node: reservation.pickup_node,
      dropoff_node: reservation.dropoff_node,
    };
  }

  return {
    selectedBooking,
    reservationInfo,
  };
};

export default connect(mapState, mapDispatch)(injectIntl(RideDetailsContainer));
