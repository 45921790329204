import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useSearchViewStyles = makeStyles((theme: CustomTheme) => ({
  searchViewContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  heading: {
    textAlign: "center",
    marginTop: "1em",
  },
  banniereJop: {
    width: "100%",
    height: "auto",
    maxWidth: "800px",
    margin: "auto",
  },
}));
