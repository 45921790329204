import React from "react";
import { useIntl } from "react-intl";

import { Paper } from "@material-ui/core";

import dayjs from "dayjs";

import { Badge } from "atoms/Badge/Badge";
import {
  TimedRideStep,
  TimedRideStepType,
} from "molecules/TimedRideStep/TimedRideStep";

import { BusIcon } from "icons/BusIcon";
import { WalkerIcon } from "icons/WalkerIcon";

import { FORMAT_HOUR } from "utils/constants";

import { useRideDetailsSimplifiedStyles } from "./rideDetailsSimplifiedStyles";
//import {
//  useJopRideTimes,
//  useSearchIsOutbound,
//} from "components/SearchForm/JOP/hooks";

export function RideDetailsSimplified({
  booking,
  reservationInfo,
  //sessionDetails,
}) {
  const intl = useIntl();
  const classes = useRideDetailsSimplifiedStyles();

  //const rideTimeMinutes = dayjs
  //  .duration({
  //    minutes: dayjs(booking.dropoff_time).diff(
  //      dayjs(booking.pickup_time),
  //      "minute"
  //    ),
  //  })
  //  .asMinutes();

  //const outbound = useSearchIsOutbound();

  //const jopRideTimes = useJopRideTimes(
  //  sessionDetails.session,
  //  rideTimeMinutes,
  //  outbound
  //);

  if (!booking) {
    return null;
  }

  const departureAddress = reservationInfo.departure_position.address;
  const departureWalkDuration = booking.pickup_walking_time;

  //const pickupTime = dayjs(booking.pickup_time).format(FORMAT_HOUR);
  //const pickupTime = jopRideTimes.earliestPickupTime;
  const estimatedPickupTime = dayjs(booking.pickup_time)
    .tz()
    .round(10, "minute")
    .format(FORMAT_HOUR);
  const pickupNodeName = booking?.pickup_node.name;
  //const pickupNodeAddress = booking?.pickup_node.position.address;
  const pickupNodeAddress = intl.formatMessage({
    id: "jop2024.addressToBeSpecifiedAfterBooking",
    defaultMessage:
      "L’adresse précise vous sera communiquée une fois la réservation effectuée",
  });

  //const roundedRideTimeMinutes = Math.round(rideTimeMinutes / 10) * 10;
  //const busDuration = `≈ ${roundedRideTimeMinutes
  //  .toFixed(0)
  //  .replace(/\b0\b/, "1")} min`;

  const arrivalAddress = reservationInfo.destination_position.address;
  const arrivalWalkDuration = booking.dropoff_walking_time;

  //const dropoffTime = dayjs(booking.dropoff_time).format(FORMAT_HOUR);
  //const dropoffTime = jopRideTimes.latestDropoffTime;
  const estimatedDropoffTime = dayjs(booking.dropoff_time)
    .tz()
    .round(10, "minute")
    .format(FORMAT_HOUR);
  const dropoffNodeName = booking?.dropoff_node.name;
  const dropoffNodeAddress = booking?.dropoff_node.position.address;

  const estimatedTripDuration = `≈ ${dayjs
    .duration({
      minutes: dayjs(booking.dropoff_time)
        .round(10, "minute")
        .diff(dayjs(booking.pickup_time).round(10, "minute"), "minute"),
    })
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min`;

  return (
    <Paper className={classes.rideDetailsContainer}>
      <ol className={classes.rideStepList}>
        {departureWalkDuration > 0 && (
          <TimedRideStep
            iconElement={
              <WalkerIcon
                className={`${classes.stepIcon} ${classes.walkerIcon} ${classes.pickupColors}`}
              />
            }
            stepAddress={departureAddress}
            timeOrDuration={`${departureWalkDuration} min`}
            type={TimedRideStepType.Walk}
          />
        )}

        <TimedRideStep
          iconElement={
            <BusIcon
              className={`${classes.stepIcon} ${classes.busIcon} ${classes.pickupColors}`}
            />
          }
          stepAddress={pickupNodeAddress}
          stepName={pickupNodeName}
          estimatedTime={estimatedPickupTime}
          type={TimedRideStepType.Pickup}
        />

        <TimedRideStep
          badgeElement={<Badge label={estimatedTripDuration} />}
          type={TimedRideStepType.Badge}
        />

        <TimedRideStep
          iconElement={
            <BusIcon
              className={`${classes.stepIcon} ${classes.busIcon} ${classes.dropoffColors}`}
            />
          }
          stepAddress={dropoffNodeAddress}
          stepName={dropoffNodeName}
          estimatedTime={estimatedDropoffTime}
          type={TimedRideStepType.Dropoff}
        />

        {arrivalWalkDuration > 0 && (
          <TimedRideStep
            iconElement={
              <WalkerIcon
                className={`${classes.stepIcon} ${classes.walkerIcon} ${classes.dropoffColors}`}
              />
            }
            stepAddress={arrivalAddress}
            timeOrDuration={`${arrivalWalkDuration} min`}
            type={TimedRideStepType.Walk}
          />
        )}
      </ol>
    </Paper>
  );
}
