import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";
import { Paper } from "@material-ui/core";
import RideSummary from "components/RideSummary/RideSummary";
import PaperBox from "components/PaperBox/PaperBox";
import { TerritoryContext } from "contexts/TerritoryContext";

import { PromoCode } from "organisms/PromoCode/PromoCode";
import { PaymentResumeDetails } from "organisms/PaymentResumeDetails/PaymentResumeDetails";
import { loadDiscountedPrices } from "containers/Payment/actions";

import { usePaymentResumeStyles } from "./paymentResumeStyles";
import { JopSessionDetails } from "components/organisms/JopSessionDetails/JopSessionDetails";

export const PaymentResume = (props) => {
  const dispatch = useDispatch();
  const classes = usePaymentResumeStyles();

  const selectedProposals = useSelector(
    (state) => state?.search?.selectedProposals,
  );
  const responses = useSelector((state) => state?.search?.responses);
  const promoCode = useSelector((state) => state?.payment?.promoCode);
  const isPromoCodeValid = useSelector(
    (state) => state?.payment?.isPromoCodeValid,
  );
  const discountedPrices = useSelector(
    (state) => state?.payment?.discountedPrices,
  );
  const personalItemsCount = useSelector(
    (state) => state?.search?.searchForm?.personalItems?.count,
  );
  const discountedPricesMultiDate = useSelector(
    (state) => state?.payment?.discountedPricesMultiDate,
  );

  const { selectedTerritory } = useContext(TerritoryContext);

  const recurringOffers =
    selectedTerritory?.booking?.recurring_bookings?.offers;
  const paymentOptions = selectedTerritory?.payment;

  const isPromoCodeEnabled = paymentOptions?.promocode?.enabled;

  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    const parsedProposals = {};

    for (const [datetime, _] of Object.entries(selectedProposals)) {
      parsedProposals[datetime] = {};
      const id = selectedProposals[datetime];
      parsedProposals[datetime].id = id;
      parsedProposals[datetime].ride = responses[datetime];

      parsedProposals[datetime].booking = parsedProposals[
        datetime
      ]?.ride?.reservation_info?.proposed_datetimes?.find((b) => b.id === id);

      parsedProposals[datetime].discountedPrice = discountedPrices?.price || 0;
      if (discountedPricesMultiDate && datetime in discountedPricesMultiDate) {
        parsedProposals[datetime].discountedPrice =
          discountedPricesMultiDate[datetime]?.price || 0;
      }
    }

    setProposals([...Object.values(parsedProposals)]);
  }, [promoCode, discountedPrices, discountedPricesMultiDate]);

  const actionLoadDiscountedPrices = () => {
    dispatch(
      loadDiscountedPrices({
        selectedProposals: selectedProposals,
        responses: responses,
        promoCode: promoCode,
        isPromoCodeValid: isPromoCodeValid,
      }),
    );
  };

  useEffect(() => {
    actionLoadDiscountedPrices();
  }, [isPromoCodeValid]);

  return (
    <div className={classes.paymentResumeContainer}>
      <PaperBox title={<FormattedMessage id="payment.summary" />}>
        {props.sessionDetails && (
          <JopSessionDetails sessionDetails={props.sessionDetails} />
        )}
        {proposals.map((proposal, i) => {
          return (
            <RideSummary
              tabIndex={props.tabIndexRef + i + 1}
              personalItemsCount={personalItemsCount}
              booking={proposal.booking}
              ride={proposal.ride}
              key={proposal.id}
              recurringOffers={recurringOffers}
              displayTicketingDiscount={true}
              discountedPrice={proposal.discountedPrice}
              sessionDetails={props.sessionDetails}
            />
          );
        })}
      </PaperBox>

      {isPromoCodeEnabled && (
        <Paper>
          <PromoCode />
        </Paper>
      )}

      <Paper>
        <PaymentResumeDetails />
      </Paper>
    </div>
  );
};
