import { useCallback, useEffect, useMemo, useState } from "react";
import { JopGamesType, JopSession } from "./JopTypes";
import jopdata from "./jopdata.json";
import dayjs from "dayjs";

export function usePersistantState<T>(
  key: string,
  initialValue: T,
  retrieveValue: boolean,
) {
  const [valueRetrieved, setValueRetrieved] = useState(false);

  if (retrieveValue && !valueRetrieved) {
    const jsonValue = localStorage.getItem(key);
    if (jsonValue !== null) {
      initialValue = JSON.parse(jsonValue);
    }
    setValueRetrieved(true);
  }

  const [value, setValue] = useState(initialValue);

  const setPersistedValue = useCallback(
    (valueToPersist: T) => {
      setValue(valueToPersist);
      localStorage.setItem(key, JSON.stringify(valueToPersist));
    },
    [key],
  );

  useEffect(() => {
    // Ensure initial value is persisted at start
    localStorage.setItem(key, JSON.stringify(initialValue));
  }, []);

  return [value, setPersistedValue] as const;
}

export type JopSessionDetails = {
  gamesType: JopGamesType;
  disciplineCode: string;
  session: JopSession;
};

export function useSearchIsOutbound() {
  const jsonValue = localStorage.getItem("jopTripDirection");
  if (jsonValue !== null) {
    return JSON.parse(jsonValue) !== "return";
  }
  return true;
}

export function useSessionDetails(sessionIdentifier: string) {
  return useMemo<JopSessionDetails | undefined>(() => {
    for (const [gamesType, disciplines] of Object.entries(jopdata.sessions)) {
      for (const [disciplineCode, sessions] of Object.entries(disciplines)) {
        if (sessions[sessionIdentifier]) {
          return {
            gamesType: gamesType as JopGamesType,
            disciplineCode,
            session: sessions[sessionIdentifier],
          };
        }
      }
    }
  }, [sessionIdentifier]);
}

export function useJopRideTimes(
  { date, startTime, endTime }: JopSession,
  rideTime: number,
  outbound: boolean,
) {
  const day = useMemo(() => dayjs(date), [date]);
  const startTimeParts = useMemo(
    () => startTime.split(":").map(Number),
    [startTime],
  );
  const endTimeParts = useMemo(() => endTime.split(":").map(Number), [endTime]);

  return useMemo(() => {
    if (outbound) {
      const sessionStartTime = day
        .set("hour", startTimeParts[0])
        .set("minute", startTimeParts[1]);
      // Earliest pickup time: session start time - 1 hour 30 minutes - ride time
      const earliestPickupTime = sessionStartTime
        .subtract(1, "hour")
        .subtract(30, "minute")
        .subtract(rideTime, "minute")
        .round(10, "minute")
        .format("HH:mm");
      // Latest pickup time: session start time - 30 minutes
      const latestDropoffTime = sessionStartTime
        .subtract(30, "minute")
        .round(10, "minute")
        .format("HH:mm");
      return { earliestPickupTime, latestDropoffTime };
    } else {
      const sessionEndTime = day
        .set("hour", endTimeParts[0])
        .set("minute", endTimeParts[1]);
      // Latest pickup time: session end time + 30 minutes
      const earliestPickupTime = sessionEndTime
        .add(30, "minute")
        .round(10, "minute")
        .format("HH:mm");
      // Earliest pickup time: session end time + 1 hour 30 minutes + ride time
      const latestDropoffTime = sessionEndTime
        .add(1, "hour")
        .add(30, "minute")
        .add(rideTime, "minute")
        .round(10, "minute")
        .format("HH:mm");
      return { earliestPickupTime, latestDropoffTime };
    }
  }, [day, startTimeParts, endTimeParts, rideTime, outbound]);
}
