import inst, { PluginFunc, UnitType } from "dayjs";

declare module "dayjs" {
  interface Dayjs {
    round(amount: number, unit: UnitType): inst.Dayjs;
  }
}

const round: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.round = function (amount, unit) {
    return this.add(
      Math.round(this.get(unit) / amount) * amount - this.get(unit),
      unit,
    ).startOf(unit);
  };
};

export default round;
