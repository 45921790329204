import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import dayjs from "dayjs";

import { UserContext } from "contexts/UserContext";

import CustomDayPickerInput from "containers/CustomDayPickerInput/CustomDayPickerInput";

import { FORMAT_DATE } from "utils/constants";

import styles from "./JopSearchForm.cssmodule.scss";
import { JopGamesType, JopSession } from "./JopTypes";
import jopdata from "./jopdata.json";
import { usePersistantState } from "./hooks";

const DATES_STR = {
  olympic: {
    from: "2024-07-24",
    to: "2024-08-11",
  },
  paralympic: {
    from: "2024-08-28",
    to: "2024-09-08",
  },
};

const DATES = {
  olympic: {
    from: new Date(dayjs(DATES_STR.olympic.from).format(FORMAT_DATE)),
    to: new Date(dayjs(DATES_STR.olympic.to).format(FORMAT_DATE)),
  },
  paralympic: {
    from: new Date(dayjs(DATES_STR.paralympic.from).format(FORMAT_DATE)),
    to: new Date(dayjs(DATES_STR.paralympic.to).format(FORMAT_DATE)),
  },
};

interface JopSessionSectionProps {
  onSessionChange: (session: JopSession | undefined) => void;
  onOrderNumberChange: (orderNumber: string) => void;
  prefillReturnTrip: boolean;
}

export default function JopSessionSection({
  onSessionChange,
  onOrderNumberChange,
  prefillReturnTrip,
}: JopSessionSectionProps) {
  const intl = useIntl();
  const { language } = useContext(UserContext);
  const [gamesType, setGamesType] = usePersistantState(
    "jopGamesType",
    "paralympic",
    prefillReturnTrip,
  );
  const [disciplineCodes, setDisciplineCodes] = useState<Array<string>>([]);
  const [disciplineCode, setDisciplineCode] = usePersistantState(
    "jopDisciplineCode",
    "",
    prefillReturnTrip,
  );
  const [day, setDay] = usePersistantState<string | null>(
    "jopDay",
    null,
    prefillReturnTrip,
  );
  const [sessions, setSessions] = useState<Array<JopSession>>([]);
  const [sessionCode, setSessionCode] = usePersistantState(
    "jopSessionCode",
    "",
    prefillReturnTrip,
  );
  const [orderNumber, setOrderNumber] = usePersistantState(
    "jopOrderNumber",
    "",
    prefillReturnTrip,
  );

  useEffect(() => {
    setDisciplineCodes(
      Object.keys(jopdata.sessions[gamesType]).sort((a, b) =>
        a.localeCompare(b),
      ),
    );
  }, [gamesType]);

  const handleGamesTypeChange = (newGamesType: JopGamesType) => {
    setGamesType(newGamesType);
    // Reset everything when games type changes
    setDisciplineCode("");
    setDay(null);
    setSessions([]);
    handleSessionChange("");
  };

  const handleSessionChange = (sessionCode: string) => {
    setSessionCode(sessionCode);
    onSessionChange(
      sessionCode === ""
        ? undefined
        : jopdata.sessions[gamesType][disciplineCode][sessionCode],
    );
  };

  useEffect(() => {
    // Update sassion list when inputs change
    if (!gamesType || !disciplineCode || !day) return;
    if (!jopdata.sessions[gamesType][disciplineCode]) return;
    const filteredSessions = Object.values<JopSession>(
      jopdata.sessions[gamesType][disciplineCode],
    )
      .filter((session) => session.date === day)
      .sort((a, b) => a.code.localeCompare(b.code));
    setSessions(filteredSessions);
    handleSessionChange(
      filteredSessions.find((session) => session.code === sessionCode)
        ? sessionCode
        : "",
    );
  }, [gamesType, disciplineCode, day]);

  useEffect(() => {
    onOrderNumberChange(orderNumber);
  }, [onOrderNumberChange, orderNumber]);

  return (
    <section>
      <Typography variant="h2">
        <FormattedMessage
          id="jop2024.form_section_1"
          defaultMessage="1. Jeux et session"
        />
      </Typography>
      <div className={styles.sessionSectionFormControls}>
        <FormControl>
          <RadioGroup
            className={styles.marginBlockAuto}
            aria-label={intl.formatMessage({
              id: "jop2024.games_type",
              defaultMessage: "Type de jeux",
            })}
            row
            value={gamesType}
            onChange={(event) => {
              handleGamesTypeChange(
                event.target.value as unknown as JopGamesType,
              );
            }}
          >
            <FormControlLabel
              value="olympic"
              control={<Radio />}
              disabled
              label={
                <FormattedMessage
                  id="jop2024.olympic_games"
                  defaultMessage="Jeux olympiques"
                />
              }
            />
            <FormControlLabel
              value="paralympic"
              control={<Radio />}
              label={
                <FormattedMessage
                  id="jop2024.paralympic_games"
                  defaultMessage="Jeux paralympiques"
                />
              }
            />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <InputLabel id="discipline-label">
            <FormattedMessage
              id="jop2024.discipline"
              defaultMessage="Discipline"
            />
          </InputLabel>
          <Select
            labelId="discipline-label"
            value={disciplineCode}
            onChange={(event: ChangeEvent<{ value: string }>) =>
              setDisciplineCode(event.target.value)
            }
          >
            {disciplineCodes
              .sort((a, b) =>
                jopdata.disciplines[a][language].localeCompare(
                  jopdata.disciplines[b][language],
                  language,
                ),
              )
              .map((disciplineCode) => (
                <MenuItem key={disciplineCode} value={disciplineCode}>
                  {jopdata.disciplines[disciplineCode][language]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl>
          <CustomDayPickerInput
            label="Date"
            fromDate={DATES[gamesType].from}
            toDate={DATES[gamesType].to}
            selectedDays={day ? [dayjs(day).format(FORMAT_DATE)] : []}
            onDayClick={(day: string) => {
              setDay(dayjs(day).format("YYYY-MM-DD"));
            }}
          />
        </FormControl>

        {sessions.length > 0 && (
          <FormControl>
            <InputLabel id="session-label">
              <FormattedMessage id="jop2024.session" defaultMessage="Session" />
            </InputLabel>
            <Select
              labelId="session-label"
              value={sessionCode}
              onChange={(event: ChangeEvent<{ value: string }>) =>
                handleSessionChange(event.target.value)
              }
            >
              {sessions.map((session) => (
                <MenuItem key={session.code} value={session.code}>
                  {session.code} ({session.startTime} - {session.endTime})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {sessions.length === 0 &&
          (day && disciplineCode ? (
            <Typography className="flex-center">
              <FormattedMessage
                id="jop2024.no_session_found"
                defaultMessage="Pas de session trouvée."
              />
            </Typography>
          ) : (
            <div />
          ))}

        <FormControl>
          <TextField
            id="session-order-number"
            label={
              <FormattedMessage
                id="jop2024.order-number"
                defaultMessage="Numéro de billet"
              />
            }
            value={orderNumber}
            onChange={(event: ChangeEvent<{ value: string }>) =>
              setOrderNumber(event.target.value)
            }
          />
        </FormControl>
      </div>
    </section>
  );
}
