// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb-cssmodule-font-dvOP9{font-family:Raleway, Arial, sans-serif;font-size:1.25rem;font-weight:600}
`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumb/breadcrumb.cssmodule.scss"],"names":[],"mappings":"AAAA,iCACE,sCAAuC,CACvC,iBAAkB,CAClB,eAAgB","sourcesContent":[".font {\n  font-family: Raleway, Arial, sans-serif;\n  font-size: 1.25rem; // 20px\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"font": `breadcrumb-cssmodule-font-dvOP9`
};
export default ___CSS_LOADER_EXPORT___;
