import React, { useContext } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";

import { Paper, Button as MuiButton } from "@material-ui/core";

import { Price } from "atoms/Price/Price";

import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";

import styles from "./confirmation.cssmodule.scss";
import ChoosedRideSummary from "components/organisms/ChoosedRideSummary/ChoosedRideSummary";

function BookingValidation(props) {
  const {
    validate,
    isRequesting,
    totalPrice,
    isOnlyOnboard,
    edition,
    responses,
    personalItemsCount,
    selectedProposals,
    isFixedLine,
    sessionDetails,
  } = props;

  const { isAuthenticated } = useContext(UserContext);
  const { selectedTerritory } = useContext(TerritoryContext);

  const isMultiDateEnabled =
    selectedTerritory.booking?.multi_date?.enabled || false;

  const buttonLabel = !isAuthenticated ? (
    <FormattedMessage id="misc.signup_and_book" />
  ) : totalPrice === 0 ? (
    <FormattedMessage id="misc.book" />
  ) : isMultiDateEnabled ? (
    <FormattedMessage id="misc.next" />
  ) : isOnlyOnboard ? (
    <FormattedMessage
      id="misc.book_price"
      defaultMessage="Book {price}"
      values={{ price: <Price value={totalPrice} /> }}
    />
  ) : edition ? (
    <FormattedMessage id="misc.book" />
  ) : (
    <FormattedMessage id="misc.next" />
  );

  return (
    <>
      <ChoosedRideSummary
        edition={edition}
        responses={responses}
        selectedProposals={selectedProposals}
        personalItemsCount={personalItemsCount}
        sessionDetails={sessionDetails}
      />

      {!isFixedLine && (
        <MuiButton
          role="link"
          variant="contained"
          onClick={validate}
          color="primary"
          disabled={isRequesting}
          style={{ margin: "0 0 0 auto" }}
          data-testid="booking-validation-btn"
        >
          {buttonLabel}
        </MuiButton>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    personalItemsCount: state?.search?.searchForm?.personalItems?.count,
  };
}

export default connect(mapStateToProps)(BookingValidation);
