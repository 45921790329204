import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { ThemeLayout } from "components/Layout/ThemeLayout";

/**
 * Layouts (Visitor & Authenticated)
 */
import AuthenticatedLayout from "components/Layout/AuthenticatedLayout.jsx";
import { UnauthenticatedLayout } from "components/Layout/UnauthenticatedLayout.jsx";

/**
 * Views
 */
import OptOutView from "views/OptOut/OptOutView.jsx";
import SignInView from "views/SignIn/SignInView.jsx";
import SSOSignInView from "views/SSOSignIn/SSOSignInView.jsx";
import OauthCallback from "views/OauthCallback/OauthCallback.jsx";
import ForgetPasswordView from "views/ForgetPassword/ForgetPasswordView.jsx";
import SignUpView from "views/SignUp/SignUpView.jsx";
import { SignUpVerificationView } from "views/SignUpVerification/SignUpVerificationView";
import JopSearchView from "views/Search/JopSearchView";
import SearchResultView from "views/SearchResult/SearchResultView.jsx";
import SearchResultConfirmationView from "views/SearchResultConfirmation/SearchResultConfirmationView.jsx";
import ProfileView from "views/Profile/ProfileView.jsx";
import ReservationListView from "views/Reservation/ListView.jsx";
import ReservationView from "views/Reservation/ReservationView.jsx";
import { RidePaymentView } from "views/RidePayment/RidePaymentView.jsx";
import EditionView from "views/Reservation/EditionView.jsx";
import EditionConfirmView from "views/Reservation/EditionConfirmView.jsx";
import ImpersonateView from "views/Impersonate/ImpersonateView.jsx";
import { NotFoundView } from "views/NotFoundView/NotFoundView.jsx";
import IncompatibleBrowserView from "views/IncompatibleBrowser/IncompatibleBrowserView.jsx";
import AboutView from "views/About/AboutView.jsx";
import { A11yDeclarationView } from "views/A11yDeclaration/A11yDeclarationView.jsx";
import { StopNotificationsView } from "views/StopNotificationsView/StopNotificationsView";

/**
 * Authentication filter
 */
import SiteMapView from "../views/SiteMapView/SiteMapView";
import { RequireAuthOrSAS } from "./RequireAuthOrSAS";
import { RequireCompatibleBrowser } from "./RequireCompatibleBrowser";
import { LookupImpersonate } from "../components/LookupImpersonate/LookupImpersonate";
import { LookupProposition } from "containers/LookupProposition/LookupProposition";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function router() {
  return (
    <ThemeLayout>
      <BrowserRouter basename={BASE_URL.replace(/\/$/, "")}>
        <SentryRoutes>
          {/* Simple layout without AppBar when unauthenticated and signup after search disabled */}
          <Route path="/" element={<UnauthenticatedLayout />}>
            <Route index element={<Navigate to="search" replace />} />
            <Route
              path="login"
              element={
                <RequireCompatibleBrowser>
                  <SignInView />
                </RequireCompatibleBrowser>
              }
            />
            <Route
              path="sso-login"
              element={
                <RequireCompatibleBrowser>
                  <SSOSignInView />
                </RequireCompatibleBrowser>
              }
            />
            <Route
              path="oauth/callback"
              element={
                <RequireCompatibleBrowser>
                  <OauthCallback />
                </RequireCompatibleBrowser>
              }
            />
            <Route
              path="forget-password-email"
              element={<ForgetPasswordView />}
            />
            <Route path="reset-password" element={<ForgetPasswordView />} />
            <Route path="unsubscribe" element={<OptOutView />} />
            <Route
              path="stop-notifications"
              element={<StopNotificationsView />}
            />
            <Route
              path="register"
              element={
                <RequireCompatibleBrowser>
                  <SignUpView />
                </RequireCompatibleBrowser>
              }
            />
            <Route
              path="register/verification"
              element={
                <RequireAuthOrSAS>
                  <SignUpVerificationView />
                </RequireAuthOrSAS>
              }
            />
            <Route
              path="browser_incompatibility"
              element={<IncompatibleBrowserView />}
            />
            <Route path="sitemap" element={<SiteMapView />} />
          </Route>

          {/* Regular layout with AppBar when authenticated or signup after search enabled */}
          <Route
            path="/"
            element={
              <RequireAuthOrSAS>
                <AuthenticatedLayout />
              </RequireAuthOrSAS>
            }
          >
            <Route path="search" element={<JopSearchView />} />
            <Route path="search/result" element={<SearchResultView />} />
            <Route
              path="search/result/confirmation"
              element={<SearchResultConfirmationView />}
            />
            <Route
              path="search/result/confirmation/payment"
              element={<RidePaymentView />}
            />
            <Route path="profile" element={<ProfileView />} />
            <Route path="reservation" element={<ReservationListView />} />
            <Route path="reservation/:id" element={<ReservationView />} />
            <Route
              path="reservation/:id/edit/confirm"
              element={<EditionConfirmView />}
            />
            <Route path="impersonate" element={<ImpersonateView />} />
            <Route path="lookup_impersonate" element={<LookupImpersonate />} />
            <Route path="confirm" element={<LookupProposition />} />
            <Route path="about" element={<AboutView />} />

            <Route path="accessibility" element={<A11yDeclarationView />} />
          </Route>

          {/* Default */}
          <Route path="*" element={<NotFoundView />} />
        </SentryRoutes>
      </BrowserRouter>
    </ThemeLayout>
  );
}
