import React from "react";
import { useIntl } from "react-intl";

import { Card, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import { InfosBadges } from "molecules/InfosBadges/InfosBadges";
import { Price } from "atoms/Price/Price";
import { TimedRide } from "organisms/TimedRide/TimedRide";

import { DiscountTicketIcon } from "components/Icons/DiscountTicket";

import { FORMAT_HOUR } from "utils/constants";

import { useResultElementStyles } from "./resultElementStyles";

export function ResultElement(props) {
  const classes = useResultElementStyles();
  const intl = useIntl();

  const { booking, index, isAriaSelected, onClick, hasDifferentNodes } = props;

  const pickupTime = dayjs
    .tz(booking.pickup_time.substring(0, booking.pickup_time.length - 5))
    .format(FORMAT_HOUR);
  const pickupNode = booking.pickup_node.name;

  const dropoffTime = dayjs
    .tz(booking.dropoff_time.substring(0, booking.dropoff_time.length - 5))
    .format(FORMAT_HOUR);
  const dropoffNode = booking.dropoff_node.name;

  const tripDuration = `${dayjs
    .duration({
      minutes: dayjs(booking.dropoff_time).diff(
        dayjs(booking.pickup_time),
        "minute",
      ),
    })
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min`;

  const walkDurationValue = dayjs.duration({
    minutes: booking.pickup_walking_time + booking.dropoff_walking_time,
  });

  const walkDuration =
    walkDurationValue.minutes() >= 1
      ? walkDurationValue.asMinutes().toFixed(0).replace(/\b0\b/, "1") + " min"
      : "";
  let discount = null;

  if (booking.discount && booking.discount > 0) {
    discount = {
      value: booking.discount,
      initialPrice: booking["initial_price"],
      type: booking.discount_info[0].discount_type,
      name: booking.discount_info[0].discount_profile_name,
    };
  }

  const price = booking?.price || 0;
  const initialPrice = discount?.initialPrice;

  const handleKeyPress = (event) => {
    if (event.key === "Enter") onClick();
  };

  return (
    <Card
      className={`${classes.card} ${
        discount !== null ? classes.cardWithDiscount : ""
      }`}
      id={"proposition-" + index}
      component="li"
      tabIndex={0}
      role="option"
      aria-label={`${intl.formatMessage(
        { id: "help.rideShort" },
        {
          pickupNode,
          pickupTime,
          dropoffNode,
          dropoffTime,
        },
      )} ${tripDuration}.${price > 0 ? <Price value={price} /> : ""}`}
      aria-selected={isAriaSelected}
      onClick={onClick}
      onKeyPress={(e) => handleKeyPress(e)}
    >
      {discount !== null && (
        <div className={classes.ticket}>
          <DiscountTicketIcon className={classes.discountTicketIcon} />

          {discount.type === "PERCENTAGE" ? (
            <Typography className={classes.ticketDetails}>
              {discount.name} -{discount.value.toFixed(2)}%
            </Typography>
          ) : (
            <Typography className={classes.ticketDetails}>
              <Price value={(discount.value * -1).toFixed(2)} />
            </Typography>
          )}
        </div>
      )}

      <TimedRide
        dropoffNodeName={dropoffNode}
        dropoffTime={dropoffTime}
        pickupNodeName={pickupNode}
        pickupTime={pickupTime}
        withBusIcon
        hasDifferentNodes={hasDifferentNodes}
      />

      <div className={classes.badgesContainer}>
        <InfosBadges
          duration={tripDuration}
          initialPrice={initialPrice}
          price={price}
        />
      </div>
    </Card>
  );
}
