import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Paper, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import { InfosBadges } from "molecules/InfosBadges/InfosBadges";
import { TimedRide } from "organisms/TimedRide/TimedRide";

import { useRideSummaryStyles } from "./rideSummaryStyles";

import { FORMAT_DATE, FORMAT_HOUR } from "utils/constants";
import { i18nVerboseDateFormat } from "utils/i18nDate";
import { capitalize } from "utils/strings";

//import {
//  useJopRideTimes,
//  useSearchIsOutbound,
//} from "components/SearchForm/JOP/hooks";

export default function RideSummary(props) {
  const {
    booking,
    discountedPrice,
    // TODO: check if this prop, previously only used for styling, is really useful
    // isSelected,
    recurringOffers,
    ride,
    personalItemsCount,
    selectBooking,
    //sessionDetails,
  } = props;

  const classes = useRideSummaryStyles();

  //const rideTimeMinutes = dayjs
  //  .duration({
  //    minutes: dayjs(booking.dropoff_time).diff(
  //      dayjs(booking.pickup_time),
  //      "minute"
  //    ),
  //  })
  //  .asMinutes();

  //const outbound = useSearchIsOutbound();

  //const jopRideTimes = useJopRideTimes(
  //  sessionDetails.session,
  //  rideTimeMinutes,
  //  outbound
  //);

  const isRideRecurring = ride?.is_recurring_service;

  const recurringOffer = recurringOffers.find(
    (offer) => offer.id === ride?.recurring_offer_id,
  );
  const recurringOfferName = recurringOffer?.name;

  const bookingDay = capitalize(
    i18nVerboseDateFormat(dayjs(booking.pickup_time).tz().format(FORMAT_DATE)),
  );

  //const pickupTime = dayjs(booking.pickup_time).format(FORMAT_HOUR);
  //const pickupTime = jopRideTimes.earliestPickupTime;
  const estimatedPickupTime = dayjs(booking.pickup_time)
    .tz()
    .round(10, "minute")
    .format(FORMAT_HOUR);
  const pickupNodeName = booking.pickup_node.name;
  //const dropoffTime = dayjs(booking.dropoff_time).format(FORMAT_HOUR);
  //const dropoffTime = jopRideTimes.latestDropoffTime;
  const estimatedDropoffTime = dayjs(booking.dropoff_time)
    .tz()
    .round(10, "minute")
    .format(FORMAT_HOUR);
  const dropoffNodeName = booking.dropoff_node.name;

  const bookingPassengers = booking?.passengers_number || 0;
  const ridePassengers = ride.reservation_info?.requested_seats;
  const standardSeats = bookingPassengers || ridePassengers;

  const wheelchairSeats =
    booking?.extras_extras_access?.requested_extras_access_seats || 0;
  const bikeSeats = booking?.extras_bike?.requested_bike_seats || 0;

  const estimatedTripDuration = `≈ ${dayjs
    .duration({
      minutes: dayjs(booking.dropoff_time)
        .round(10, "minute")
        .diff(dayjs(booking.pickup_time).round(10, "minute"), "minute"),
    })
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min`;

  const [initialPrice, setInitialState] = useState(0);
  const [price, setPrice] = useState(0);

  const paymentDiscounts = booking?.payment?.payment_discount_values || [];

  useEffect(() => {
    if (booking?.initial_price) {
      setInitialState(booking.initial_price);
    }
    if (booking?.price) {
      setPrice(booking.price);
    }
    if (discountedPrice) {
      setPrice(discountedPrice);
    }
  }, [booking, discountedPrice]);

  const discounts = paymentDiscounts.map((discount) => {
    return {
      name: discount?.discount_profile?.name,
      seats: discount?.passengers_number,
    };
  });

  return (
    <Paper
      className={classes.rideSummaryContainer}
      tabIndex={selectBooking ? 0 : -1}
      onClick={selectBooking}
      onKeyDown={(e) => {
        e.stopPropagation();

        if (e.keyCode === 13 || e.keyCode === 32) {
          selectBooking();
        }
      }}
    >
      {isRideRecurring ? (
        <>
          <Typography className={classes.summaryTitle} component="h2">
            {recurringOfferName}
          </Typography>

          <Typography className={classes.summarySubTitle} component="h3">
            <FormattedMessage
              id="search.multi_date"
              values={{
                startDate: i18nVerboseDateFormat(ride.requested_datetime),
                endDate: i18nVerboseDateFormat(
                  ride.reservation_info.recurring_end_datetime,
                ),
              }}
            />
          </Typography>
        </>
      ) : (
        <Typography className={classes.summaryTitle} component="h2">
          {bookingDay}
        </Typography>
      )}

      <div className={classes.summaryTimedRide}>
        <TimedRide
          dropoffNodeName={dropoffNodeName}
          dropoffTime={estimatedDropoffTime}
          pickupNodeName={pickupNodeName}
          pickupTime={estimatedPickupTime}
          withBusIcon
          isLabelTop
        />
      </div>

      <div className={classes.summaryInfosBadges}>
        <InfosBadges
          bikeSeats={bikeSeats}
          discounts={discounts}
          duration={estimatedTripDuration}
          initialPrice={initialPrice}
          price={price}
          allSeats={standardSeats}
          wheelchairSeats={wheelchairSeats}
          personalItems={personalItemsCount}
        />
      </div>
    </Paper>
  );
}
