import * as React from "react";

import { FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";

import { BusIcon } from "icons/BusIcon";

import { useTimedNodeStyles } from "./timedNodeStyles";
import { cn } from "utils/classes";

type TimeNodeProps = {
  isDropoffNode?: boolean;
  nodeName: string;
  time?: string;
  withBusIcon?: boolean;
  isLabelTop?: boolean;
  isDifferentNode?: boolean;
};

export function TimedNode({
  isDropoffNode,
  nodeName,
  time,
  withBusIcon,
  isLabelTop = false,
  isDifferentNode,
}: TimeNodeProps) {
  const classes = useTimedNodeStyles();

  return (
    <>
      {isLabelTop ? (
        <span className={classes.nodeLabel}>
          {!isDropoffNode ? (
            <FormattedMessage id="ride_details.estimated_pu_time" />
          ) : (
            <FormattedMessage id="ride_details.estimated_do_time" />
          )}
        </span>
      ) : null}
      <Typography className={classes.timedNodeContainer}>
        <span className={cn(classes.time, { "text-muted-foreground": !time })}>
          {time || "--:--"}
        </span>
        {withBusIcon ? (
          <BusIcon
            className={`${classes.busIcon} ${
              isDropoffNode ? classes.dropoffColors : classes.pickupColors
            }`}
          />
        ) : (
          <span
            className={`${classes.bullet} ${
              isDropoffNode ? classes.dropOffBullet : ""
            }`}
          ></span>
        )}
        <span className={classes.nodeNameLabelWrapper}>
          <span
            className={`${classes.nodeName} ${isDifferentNode && classes.differentNode}`}
          >
            {nodeName}
          </span>
          {!isLabelTop ? (
            <span className={classes.nodeLabel}>
              -&nbsp;
              {!isDropoffNode ? (
                <FormattedMessage id="ride_details.estimated_pu_time" />
              ) : (
                <FormattedMessage id="ride_details.estimated_do_time" />
              )}
            </span>
          ) : null}
        </span>
      </Typography>
    </>
  );
}
