// 100km radius is the current limit we use when building the matrices of a new territory
const RADIUS = 100000; // in meters
const DELTA_LAT_LNG = RADIUS / 1000000; // delta in lat/lng to be used to mimick the radius constraint (as a rectangle rather than a circle)

export function googleAutocomplete(address, lat, lng, radius) {
  // this function is used for adress autocomplete purposes
  // It does *NOT* retrieve or store lat/lng information
  // for resulting propositions
  // This will be done at the last minute by googleGeoCode
  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.maps.places) {
      reject("Google maps library not loaded");
      return;
    }

    if (!address.trim()) {
      // let's not run useless requests
      // but still reset results database for proper display
      resolve([]);
      return;
    }

    const circle = new window.google.maps.Circle({
      center: { lat, lng },
      radius: radius || RADIUS,
    });

    const autocomplete = new window.google.maps.places.AutocompleteService();
    autocomplete
      .getPlacePredictions({
        input: address,
        locationBias: circle,
      })
      .then((results) => {
        return resolve(
          results.predictions.map((p) => ({
            place_id: p.place_id,
            zipcode: p.zipcode,
            address: p.description,
          })),
        );
      });
  });
}

export function googleGeoCode({ address, lat, lng, placeId }) {
  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.maps.Geocoder) {
      reject("Google maps library not loaded");
      return;
    }
    if (!address.trim()) {
      // let's not run useless requests
      // but still reset results database for proper display
      resolve([]);
      return;
    }

    // maybe not the optimal way re-instanciating it everytime, should be a class ?
    const geocoder = new window.google.maps.Geocoder();
    const bounds =
      lat && lng
        ? new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(
              lat + DELTA_LAT_LNG,
              lng - DELTA_LAT_LNG,
            ),
            new window.google.maps.LatLng(
              lat - DELTA_LAT_LNG,
              lng + DELTA_LAT_LNG,
            ),
          )
        : null;
    geocoder.geocode(
      {
        placeId,
        address: placeId ? undefined : address,
        bounds,
      },
      (results, status) => {
        if (_.isEmpty(results)) {
          reject();
          return;
        }
        if (status !== "OK") {
          reject("couldn't update with proper google geocode coords");
          return;
        }
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        const zipcode = _.reduce(
          results[0].address_components,
          (zipcode, b) => {
            if (zipcode) {
              return zipcode;
            }
            if (b.types[0] === "postal_code") return b.long_name;
          },
          null,
        );
        resolve({
          latitude,
          longitude,
          zipcode,
          address: results[0].formatted_address,
        });
      },
    );
  });
}
