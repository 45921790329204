import { useCallback, useState } from "react";

import * as Sentry from "@sentry/react";

import api from "apiAxios";
import store from "store/store.js";

import { GetCustomerProfileOutput } from "api/types/getCustomerProfile";
import {
  UpdateUserPasswordInput,
  UpdateUserPasswordOutput,
} from "api/types/updateUserPassword";
import {
  UpdateUserProfileInput,
  UpdateUserProfileOutput,
} from "api/types/updateUserProfile";
import {
  UpdateUserProfileByAdminInput,
  UpdateUserProfileByAdminOutput,
} from "api/types/updateUserProfileByAdmin";

import {
  Territory,
  UserPasswordDataForUpdate,
  UserProfile,
  UserProfileAfterUpdate,
  UserProfileAfterUpdateByAdmin,
  UserProfileDataForUpdate,
  UserProfileDataForUpdateByAdmin,
  UseUserProfileValue,
} from "./types";
import { PROFILE_FETCHED } from "store/actions";
import { snackMessage } from "containers/SnackBar/actions";

const LOCAL_STORAGE_HAS_TERMS_ACCEPTED_KEY = "HAS_TERMS_ACCEPTED";

const defaultUserProfile: UserProfile = {
  id: undefined,
  customerId: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phoneNumber: undefined,
  isPhoneValidated: undefined,
  hasTermsAccepted:
    window.localStorage.getItem(LOCAL_STORAGE_HAS_TERMS_ACCEPTED_KEY) ===
    "true",
  optInMarket: undefined,
  groups: [],
  custom_fields: {
    birthDate: undefined,
  },
};

export function useUserProfile(
  selectedTerritoryKey?: string,
): UseUserProfileValue {
  const [userProfile, setUserProfile] =
    useState<UserProfile>(defaultUserProfile);
  const [userTerritories, setUserTerritories] = useState<Territory[]>([]);
  const [restoreSavedTerritory, setRestoreSavedTerritory] = useState(false);
  const [savedTerritoryKey, setSavedTerritoryKey] = useState<
    string | undefined
  >(undefined);
  const [userProfileLoading, setUserProfileLoading] = useState<boolean>(false);
  const [userProfileHasNeverBeenLoaded, setUserProfileHasNeverBeenLoaded] =
    useState<boolean>(true);

  const onSavedTerritoryRestored = useCallback(() => {
    setRestoreSavedTerritory(false);
  }, []);

  const loadUserProfile = useCallback(
    async (dontSendTerritory = false): Promise<UserProfile> => {
      setUserProfileLoading(true);
      setUserProfileHasNeverBeenLoaded(false);

      let queryParameters: { territory?: string } = {};
      if (!dontSendTerritory && selectedTerritoryKey) {
        queryParameters = {
          territory: selectedTerritoryKey,
        };
      }

      return api
        .getCustomerProfile({
          queryParameters,
        })
        .then((userProfileApiData: GetCustomerProfileOutput) => {
          if (
            process.env.NODE_ENV === "production" &&
            process.env.SENTRY_PUBLIC_DSN
          ) {
            Sentry.configureScope((scope) => {
              scope.setUser({ id: userProfileApiData.user.id.toString() });
            });
          }

          const newUserProfile = {
            id: userProfileApiData.user.id,
            customerId: userProfileApiData.user.customer_id,
            firstName: userProfileApiData.user.first_name,
            lastName: userProfileApiData.user.last_name,
            email: userProfileApiData.user.email,
            phoneNumber: userProfileApiData.user.phone_number,
            isPhoneValidated: userProfileApiData.user.is_phone_validated,
            hasTermsAccepted: userProfileApiData.user.has_terms_accepted,
            optInMarket: userProfileApiData.user.opt_in_market,
            groups: userProfileApiData.groups,
            custom_fields: userProfileApiData.user.custom_fields,
          };

          setUserProfile(newUserProfile);
          setUserTerritories(userProfileApiData.territory_parameters.locals);
          setSavedTerritoryKey(userProfileApiData.user.territory_key);
          if (dontSendTerritory && selectedTerritoryKey) {
            setRestoreSavedTerritory(true);
          }

          store.dispatch({
            type: PROFILE_FETCHED,
            profile: userProfileApiData,
          });

          return newUserProfile;
        })
        .catch((error) => {
          if (
            error.infos &&
            error.infos.reason &&
            error.infos.reason === api.UNAUTHORIZED
          ) {
            store.dispatch(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore: don't know how to solve Redux typing issue
              // but it doesn't matter because it will disappear eventually
              snackMessage("error", "Your session has expired.", error),
            );
          } else {
            store.dispatch(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore: don't know how to solve Redux typing issue
              // but it doesn't matter because it will disappear eventually
              snackMessage(
                "error",
                "An unknown error occured while fetching the user's profile. Please contact your operator.",
                error,
              ),
            );
          }

          throw error;
        })
        .finally(() => {
          setUserProfileLoading(false);
        });
    },
    [selectedTerritoryKey],
  );

  const updateHasTermsAccepted = useCallback(
    (hasTermsAccepted: boolean): void => {
      // Combine current profile data with new profile data
      const updatedUserProfileData: UserProfile = {
        ...userProfile,
        hasTermsAccepted,
      };

      // Update profile
      setUserProfile(updatedUserProfileData);

      // Save hasTermsAccepted into local storage
      localStorage.setItem(
        LOCAL_STORAGE_HAS_TERMS_ACCEPTED_KEY,
        hasTermsAccepted.toString(),
      );
    },
    [userProfile],
  );

  const updateUserProfile = useCallback(
    async (
      newUserProfileData: UserProfileDataForUpdate,
    ): Promise<UserProfileAfterUpdate> => {
      // Combine current profile data with new profile data
      const updatedUserProfileData: UserProfile = {
        ...userProfile,
        ...newUserProfileData,
      };

      const updatedUserProfileDataForApi: UpdateUserProfileInput = {
        email: newUserProfileData.email,
        phone_number: updatedUserProfileData.phoneNumber,
        last_name: newUserProfileData.lastName,
        first_name: newUserProfileData.firstName,
        opt_in_market: updatedUserProfileData.optInMarket,
        has_terms_accepted: updatedUserProfileData.hasTermsAccepted,
        custom_fields: newUserProfileData.custom_fields,
      };

      return api
        .updateUserProfile({
          payload: updatedUserProfileDataForApi,
          queryParameters: {
            territory: selectedTerritoryKey,
          },
        })
        .then((userProfileApiData: UpdateUserProfileOutput) => {
          const newUserProfile = {
            id: userProfileApiData.user.id,
            firstName: userProfileApiData.user.first_name,
            lastName: userProfileApiData.user.last_name,
            email: userProfileApiData.user.email,
            phoneNumber: userProfileApiData.user.phone_number,
            isPhoneValidated: userProfileApiData.user.is_phone_validated,
            hasTermsAccepted: userProfileApiData.user.has_terms_accepted,
            optInMarket: userProfileApiData.user.opt_in_market,
            groups: userProfile.groups,
            custom_fields: userProfileApiData.user.custom_fields,
          };

          // Update profile again with the data returned by the API
          setUserProfile(newUserProfile);

          return {
            userProfileApiData,
            newUserProfile,
          };
        });
    },
    [userProfile, selectedTerritoryKey],
  );

  const updateUserProfileByAdmin = useCallback(
    async ({
      password,
      ...newUserProfileData
    }: UserProfileDataForUpdateByAdmin): Promise<UserProfileAfterUpdateByAdmin> => {
      // Combine current profile data with new profile data
      const updatedUserProfileData: UserProfile = {
        ...userProfile,
        ...newUserProfileData,
      };

      // Has phone number changed
      const hasPhoneNumberChanged =
        userProfile.phoneNumber !== newUserProfileData.phoneNumber;

      const updatedUserProfileDataForApi: UpdateUserProfileByAdminInput = {
        id: newUserProfileData.id,
        email: updatedUserProfileData.email,
        phone_number: updatedUserProfileData.phoneNumber,
        last_name: newUserProfileData.lastName,
        first_name: newUserProfileData.firstName,
        // Force is_phone_validated to true if phone number has changed
        is_phone_validated:
          hasPhoneNumberChanged || userProfile.isPhoneValidated,
        password: password,
        opt_in_market: updatedUserProfileData.optInMarket,
        has_terms_accepted: updatedUserProfileData.hasTermsAccepted,
        custom_fields: updatedUserProfileData.custom_fields,
      };

      return api
        .updateUserProfileByAdmin({
          payload: updatedUserProfileDataForApi,
          urlParameters: {
            id: newUserProfileData.id,
          },
          queryParameters: {
            territory: selectedTerritoryKey,
          },
        })
        .then((userProfileApiData: UpdateUserProfileByAdminOutput) => {
          const newUserProfile = {
            id: userProfileApiData.id,
            firstName: userProfileApiData.first_name,
            lastName: userProfileApiData.last_name,
            email: userProfileApiData.email,
            phoneNumber: userProfileApiData.phone_number,
            isPhoneValidated: userProfileApiData.is_phone_validated,
            hasTermsAccepted: userProfileApiData.has_terms_accepted,
            optInMarket: userProfileApiData.opt_in_market,
            groups: userProfile.groups,
            custom_fields: userProfileApiData.custom_fields,
          };

          // Update profile again with the data returned by the API
          setUserProfile(newUserProfile);

          return { userProfileApiData, newUserProfile };
        });
    },
    [userProfile, selectedTerritoryKey],
  );

  const updateUserPassword = useCallback(
    async (
      newPasswordData: UserPasswordDataForUpdate,
    ): Promise<UpdateUserPasswordOutput> => {
      const updateUserPasswordData: UpdateUserPasswordInput = {
        current_password: newPasswordData.currentPassword,
        new_password: newPasswordData.newPassword,
        new_password_confirmation: newPasswordData.newPasswordConfirm,
      };
      return api.updateUserPassword({
        payload: updateUserPasswordData,
      });
    },
    [],
  );

  return {
    loadUserProfile,
    onSavedTerritoryRestored,
    restoreSavedTerritory,
    savedTerritoryKey,
    userTerritories,
    updateHasTermsAccepted,
    updateUserPassword,
    updateUserProfile,
    updateUserProfileByAdmin,
    userProfile,
    userProfileHasNeverBeenLoaded,
    userProfileLoading,
  };
}
