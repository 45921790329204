import React, { useContext, useEffect, useState } from "react";

import { CircularProgress, Grid } from "@material-ui/core";

import { ChangeSeatsDialog } from "components/ChangeSeats/ChangeSeatsDialog";
import { DriverDetails } from "molecules/DriverDetails/DriverDetails";
import { PaymentDetails } from "organisms/PaymentDetails/PaymentDetails";
import { JopSessionDetails } from "components/organisms/JopSessionDetails/JopSessionDetails";
import RideDetails from "containers/RideDetails/RideDetails";
import RideMap from "containers/RideMap/RideMap";
import RideSchedules from "containers/RideSchedules/RideSchedules";

import { useReservationStyles } from "./reservationStyles";
import { getPassengersBookedProfilesIds } from "containers/PassengersProfiles/actions";
import { useDispatch } from "react-redux";
import { UniversalLinkDialog } from "components/UniversalLinkDialog/UniversalLinkDialog";
import { TerritoryContext } from "contexts/TerritoryContext";
import { useIntl } from "react-intl";
import { useSessionDetails } from "components/SearchForm/JOP/hooks";

/**
 * Booking details component /reservation/:id
 * @desc This component is used to display the booking details schedule, map, driver details, payment details
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function Reservation(props) {
  const { canChangeSeats, isDeletable, isNotPast, isRecurring, reservation } =
    props;
  const intl = useIntl();
  const showDriverDetails = reservation?.driver && reservation?.bus;

  const [isChangeSeatsDialogOpen, setIsChangeSeatsDialogOpen] = useState(false);
  const [isUniversalLinkDialogOpen, setIsUniversalLinkDialogOpen] =
    useState(false);
  const [hasUniversalLink, setHasUniversalLink] = useState(false);
  const { selectedTerritory } = useContext(TerritoryContext);

  useEffect(() => {
    if (selectedTerritory?.booking?.universal_links?.length > 0) {
      setHasUniversalLink(true);
    }
  }, [selectedTerritory?.booking?.universal_link]);
  const classes = useReservationStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPassengersBookedProfilesIds());
  }, []);

  const sessionIdentifier = reservation?.custom_fields?.SESSION_IDENTIFIER;
  const sessionDetails = useSessionDetails(sessionIdentifier);

  return (
    <Grid container spacing={2} justifyContent="center">
      {!reservation && <CircularProgress />}

      {reservation && (
        <>
          <Grid
            item
            xs={12}
            md={showDriverDetails ? 6 : 10}
            className={classes.grid}
          >
            <RideSchedules
              canChangeSeats={canChangeSeats}
              isDeletable={isDeletable}
              isNotPast={isNotPast}
              isRecurring={isRecurring}
              hasUniversalLink={hasUniversalLink}
              reservation={reservation}
              setIsChangeSeatsDialogOpen={() => {
                setIsChangeSeatsDialogOpen(true);
              }}
              setIsUniversalLinkDialogOpen={() => {
                setIsUniversalLinkDialogOpen(true);
              }}
            />
          </Grid>

          {showDriverDetails && (
            <Grid item xs={12} md={4} className={classes.grid}>
              <DriverDetails reservation={reservation} />
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            sm={6}
            md={5}
            className={classes.detailsColumn}
          >
            {sessionDetails && (
              <Grid item xs={12}>
                <JopSessionDetails sessionDetails={sessionDetails} />
              </Grid>
            )}

            <Grid item xs={12}>
              <RideDetails
                reservation={reservation}
                sessionDetails={sessionDetails}
              />
            </Grid>

            {(reservation?.payment?.initial_amount || 0) > 0 && (
              <Grid item xs={12}>
                <PaymentDetails reservation={reservation} />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={5} className={classes.rideMapContainer}>
            <RideMap reservation={reservation} />
          </Grid>

          {isChangeSeatsDialogOpen && (
            <ChangeSeatsDialog
              reservation={reservation}
              onClose={() => {
                setIsChangeSeatsDialogOpen(false);
              }}
            />
          )}
          {isUniversalLinkDialogOpen && (
            <UniversalLinkDialog
              titleKey="title.buy_ticket"
              alertTextKey="information.external_ticketer_redirection"
              alertKeyParams={{
                onboardPaymentMethods:
                  selectedTerritory?.payment?.onboard?.onboard_payment_modes
                    ?.map((mode) =>
                      intl.formatMessage({
                        id: `LABEL_PAYMENT_MODE_${mode?.name.replace(" ", "_").toUpperCase()}`,
                      }),
                    )
                    ?.join(", "),
                br: <br />,
              }}
              handleClose={() => {
                setIsUniversalLinkDialogOpen(false);
              }}
            />
          )}
        </>
      )}
    </Grid>
  );
}
