import { createIntl } from "react-intl";
import { initializePhraseAppEditor } from "react-intl-phraseapp";

import { settings } from "config/app";

// for old browser compatibility
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/fr"; // Add locale data for fr
import "@formatjs/intl-pluralrules/locale-data/en";

// Line below bugs in Safari
// import '@formatjs/intl-relativetimeformat/polyfill'
import "@formatjs/intl-relativetimeformat/locale-data/fr"; // Add locale data for fr
import "@formatjs/intl-relativetimeformat/locale-data/en";

import enTranslations from "./translations/en.json";
import frTranslations from "./translations/fr.json";

export const translations = {
  en: enTranslations,
  fr: frTranslations,
};

/** our cached version of the IntlProvider we pass through redux */
let _intl = null;

/**
 * This function will be injected into each thunk-actions through
 * the getIntl prop. It allows to use the IntlProvider API inside redux actions
 */

export const getLang = () => {
  const currentLanguage = window.localStorage.getItem(
    settings.localStorageKeys.language,
  );
  const browser_says =
    (navigator.languages ? navigator.languages[0] : false) ||
    navigator.language ||
    navigator.browserLanguage ||
    "en";

  const lang = currentLanguage || browser_says.split("-")[0].toLowerCase();

  return translations[lang] ? lang : "en";
};

export const getMessages = (language) => {
  const lang = language || getLang();

  if (translations[lang]) {
    // Any other language fallbacks to English
    return { ...translations.en, ...translations[lang] };
  }

  // Default language: English
  return translations.en;
};

export const getIntl = () => {
  if (!_intl) {
    _intl = createIntl({
      locale: getLang(),
      messages: getMessages(),
    });
  }

  return _intl;
};

const phraseAppEditorConfig = {
  projectId: "7eb2b91af8f981d6f72ac07efa0d989f",
  phraseEnabled:
    typeof PHRASE_IN_CONTEXT_EDITOR === "boolean"
      ? PHRASE_IN_CONTEXT_EDITOR
      : false,
  prefix: "[[__",
  suffix: "__]]",
  fullReparse: true,
};

initializePhraseAppEditor(phraseAppEditorConfig);
