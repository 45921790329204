import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useAccountInfosStyles = makeStyles((theme: CustomTheme) => ({
  accountInfoContainer: {
    width: "100%",
  },
  fieldsContainer: {
    display: "grid",
    gridGap: "16px 32px",

    margin: "2rem 0",

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  saveButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  phoneNumberTooltip: {
    marginLeft: "8px",
    fontSize: "30px",
  },
}));
