/**
 * Returns the more accessible text color for a given background color.
 * @param {string} bgColor
 * @param {string} lightTextColor
 * @param {string} darkTextColor
 * @returns {string}
 */
export function getAccessibleTextColor(backgroundColor) {
  backgroundColor = backgroundColor.replace("#", "");

  const red = parseInt(backgroundColor.substring(0, 2), 16);
  const green = parseInt(backgroundColor.substring(2, 4), 16);
  const blue = parseInt(backgroundColor.substring(4, 6), 16);

  const normalizedColors = [red / 255, green / 255, blue / 255];

  const linearRGB = normalizedColors.map((ratio) =>
    ratio <= 0.03928 ? ratio / 12.92 : ((ratio + 0.055) / 1.055) ** 2.4,
  );

  const relativeLuminance =
    0.2126 * linearRGB[0] + 0.7152 * linearRGB[1] + 0.0722 * linearRGB[2];

  return relativeLuminance > 0.179 ? "#000000" : "#ffffff";
}

/**
 * Returns the opposite hexa color code for a given color.
 * @param {string} hexaColorCode
 * @returns {string}
 */
export function invertColor(hexaColorCode) {
  hexaColorCode = hexaColorCode.replace("#", "");

  let invertedRed = (
    255 - parseInt(hexaColorCode.substring(0, 2), 16)
  ).toString(16);
  let invertedGreen = (
    255 - parseInt(hexaColorCode.substring(2, 4), 16)
  ).toString(16);
  let invertedBlue = (
    255 - parseInt(hexaColorCode.substring(4, 6), 16)
  ).toString(16);

  invertedRed = invertedRed.padStart(2, "0");
  invertedGreen = invertedGreen.padStart(2, "0");
  invertedBlue = invertedBlue.padStart(2, "0");

  return `#${invertedRed}${invertedGreen}${invertedBlue}`;
}
