// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;0,900;1,400&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://s3-eu-west-1.amazonaws.com/dev-bookingzone/jop2024/vendors/paris2024/paris2024-fonts.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* JOP font: Raleway - 400, 400 italic, 600, 900 */

/* JOP font: Paris 2024 - Variable */
`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA,kDAAkD;;AAGlD,oCAAoC","sourcesContent":["/* JOP font: Raleway - 400, 400 italic, 600, 900 */\n@import url(\"https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;0,900;1,400&display=swap\");\n\n/* JOP font: Paris 2024 - Variable */\n@import url(\"https://s3-eu-west-1.amazonaws.com/dev-bookingzone/jop2024/vendors/paris2024/paris2024-fonts.css\");\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
