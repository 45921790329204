import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  useTheme,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import { settings } from "config/app";
import { AuthModalsContext, AuthModal } from "contexts/AuthModalsContext";
import { ProductContext } from "contexts/ProductContext";
import { UserContext } from "contexts/UserContext";
import { TerritoryContext } from "contexts/TerritoryContext";
import Breadcrumb from "containers/Breadcrumb/Breadcrumb.jsx";
import LanguageSelector from "components/molecules/LanguageSelector/LanguageSelector";
import ImpersonateDialog from "components/ImpersonateDialog/ImpersonateDialog";
import { userManager } from "containers/SSOSignInForm/ssoUserManager";

import ActionInfo from "../Icons/ActionInfo";
import ActionLogOut from "../Icons/ActionLogOut";
import { invertColor } from "styles/textColor";
import styles from "./appBar.cssmodule.scss";
import cookie from "lib/cookie";
import BurgerMenu from "components/Icons/BurgerMenu";

const JOP = true;

export default function AppBar(props) {
  const { isRequestingImpersonate } = props;

  const { showAuthModal } = useContext(AuthModalsContext);
  const { productParameters } = useContext(ProductContext);
  const { territories, selectedTerritory, setSwitchTerritoryDialogOpen } =
    useContext(TerritoryContext);
  const { setIsAuthenticated, isAuthenticated, loadUserProfile } =
    useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const halfCollapsedMode = useMediaQuery((theme) =>
    theme.breakpoints.down("md"),
  );
  const collapsedMode = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const isSurveyEnabled =
    productParameters?.features?.booking_website
      ?.is_call_center_survey_enabled || false;

  const userInitials =
    props.user.first_name &&
    props.user.last_name &&
    (
      props.user.first_name.charAt(0) + props.user.last_name.charAt(0)
    ).toUpperCase();

  const textColor = theme.palette.textColor;
  const oppositeColor = invertColor(textColor);
  /**
   * If we're on the search page the appbar should be transparent
   */
  const transparent = location.pathname === "/search";

  userManager.events.addAccessTokenExpiring(async () => {
    const user = await userManager.getUser();
    cookie.set(settings.cookieKeys.authCookie, user.access_token, 2);
  });

  userManager.events.addAccessTokenExpired(async () => {
    setIsAuthenticated(false);
    cookie.set(settings.cookieKeys.authCookie, null);
    navigate("/sso-login");
  });

  const signUpAndSignInButtons =
    SIGNUP_AFTER_SEARCH && !isAuthenticated
      ? [
          <Button
            key="signup"
            variant="outlined"
            size="medium"
            style={{
              width: 190,
              color: textColor,
              borderColor: textColor,
              backgroundColor: theme.palette.primary.main,
            }}
            onClick={() => showAuthModal(AuthModal.SignUp)}
          >
            <FormattedMessage id="link.register" />
          </Button>,
          <Button
            key="signin"
            variant="contained"
            size="medium"
            style={{
              width: 190,
              backgroundColor: textColor,
              color: oppositeColor,
            }}
            onClick={() => showAuthModal(AuthModal.SignIn)}
          >
            <FormattedMessage id="signin.connexion" />
          </Button>,
        ]
      : [];

  const menu = (
    <Menu
      open={props.showMenu}
      anchorEl={props.menuTargetElement}
      keepMounted
      onClose={props.closeMenu}
      id="menu"
      style={{ color: textColor }}
    >
      {isAuthenticated && [
        <MenuItem
          key="account information"
          aria-label="account informations"
          onClick={() => props.redirect(navigate, "/profile")}
        >
          <Avatar style={{ marginRight: "0.5rem" }}>{userInitials}</Avatar>

          <div className="column">
            <Typography className="fullWidth">
              {props.user.first_name + " " + props.user.last_name}
            </Typography>

            <Typography variant="caption" className="fullWidth">
              {props.intl.formatMessage({ id: "menu.account" })}
            </Typography>
          </div>
        </MenuItem>,

        <Divider aria-hidden="true" key="divider" />,
      ]}

      {collapsedMode &&
        signUpAndSignInButtons.map((button) => (
          <MenuItem key={button.key}>{button}</MenuItem>
        ))}

      <MenuItem key="home" onClick={() => props.redirect(navigate, "/")}>
        <Typography>
          {props.intl.formatMessage({ id: "menu.booking" })}
        </Typography>
      </MenuItem>

      {isAuthenticated && [
        <MenuItem
          key="my rides"
          onClick={() => props.redirect(navigate, "/reservation")}
          data-testid="my-rides"
        >
          <Typography>
            {props.intl.formatMessage({ id: "menu.journey" })}
          </Typography>
        </MenuItem>,
        ...[
          /* CREDIT */
          selectedTerritory?.payment?.credit?.enabled && (
            <MenuItem
              key="wallet"
              aria-label="wallet"
              onClick={() => props.redirect(navigate, "/profile#wallet")}
              data-testid="payment-methods"
            >
              <Typography>
                {props.intl.formatMessage({ id: "credit.wallet" })}
              </Typography>
            </MenuItem>
          ),
        ],
      ]}

      {territories && territories.length > 1 && (
        <MenuItem
          key="territories"
          onClick={() => {
            props.closeMenu();
            setSwitchTerritoryDialogOpen(true);
          }}
        >
          <Typography>
            {props.intl.formatMessage({
              id: "menu.switch_territory",
            })}
          </Typography>
        </MenuItem>
      )}

      {props.canImpersonate() && (
        <MenuItem
          aria-label="Impersonate user"
          onClick={() => {
            props.closeMenu();
            if (props.isAdmin) props.redirect(navigate, "/impersonate");
            // ImpersonateDialog is for user referent
            else props.openImpersonateDialog();
          }}
          data-testid="impersonate-list-item"
        >
          <Typography>
            {props.intl.formatMessage({ id: "menu.impersonate" })}
            {isSurveyEnabled && (
              <span
                className={styles.badge}
                style={{ borderColor: "blue", color: "blue" }}
              >
                {props.intl.formatMessage({ id: "misc.new" })}
              </span>
            )}
          </Typography>
        </MenuItem>
      )}

      <Divider aria-hidden="true" />

      <MenuItem
        key="about"
        aria-label="about"
        onClick={() => props.redirect(navigate, "/about")}
      >
        <ActionInfo /> &nbsp;
        <Typography>
          {props.intl.formatMessage({ id: "menu.about" })}
        </Typography>
      </MenuItem>

      {isAuthenticated && (
        <MenuItem
          key="logout"
          data-testid="menu-item-logout"
          aria-label={<FormattedMessage id="menu.logout" />}
          onClick={() => props.logout(navigate, setIsAuthenticated)}
        >
          <ActionLogOut /> &nbsp;
          <Typography>
            {props.intl.formatMessage({ id: "menu.logout" })}
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <header style={{ background: theme.palette.primary.main }} role="banner">
      <nav
        className={`${styles.wrapper} ${transparent ? styles.transparent : ""}`}
      >
        <div className={styles.actionsContainer}>
          {menu}

          <div
            tabIndex={0}
            role="button"
            className={styles.actions}
            onClick={props.openMenu}
            onKeyPress={(e) => (e.key === "Enter" ? props.openMenu(e) : null)}
            aria-controls="menu"
            aria-haspopup="true"
            style={{ color: textColor }}
            data-testid="menu-button"
          >
            <div
              className={styles.username}
              style={{
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main,
              }}
            >
              <BurgerMenu />
              <span className="visuallyHidden">
                <FormattedMessage id="menu.menu" />
              </span>
            </div>
          </div>
          {!collapsedMode && <LanguageSelector textColor={textColor} />}
        </div>

        <div className="column">
          <Link to="/" className={"column " + styles.logo} tabIndex={0}>
            <img
              src="https://s3-eu-west-1.amazonaws.com/dev-bookingzone/jop2024/logo-jop.png"
              alt={`${productParameters?.brand} (${props.intl.formatMessage({
                id: "go_to_home",
              })})`}
            />
          </Link>

          {halfCollapsedMode && selectedTerritory && territories.length > 1 && (
            <Typography
              variant="body1"
              style={{ color: textColor }}
              onClick={() => {
                setSwitchTerritoryDialogOpen(true);
              }}
            >
              {selectedTerritory?.geography?.area_name}
            </Typography>
          )}
        </div>

        {
          /* little chip showing IMPERSONATE */
          (props.impersonateSession || isRequestingImpersonate) && (
            <div className={styles.impersonateWrapper}>
              {isRequestingImpersonate ? (
                <Chip avatar={<CircularProgress />} />
              ) : (
                <Chip
                  clickable
                  className="hover"
                  component="button"
                  onClick={() =>
                    props.closeImpersonateSession(navigate, loadUserProfile)
                  }
                  onDelete={() =>
                    props.closeImpersonateSession(navigate, loadUserProfile)
                  }
                  aria-label="impersonate_chip"
                  label={
                    isRequestingImpersonate ? (
                      <CircularProgress />
                    ) : (
                      `${props.impersonateUser.first_name} ${props.impersonateUser.last_name}`
                    )
                  }
                  data-testid="impersonate-badge"
                />
              )}
            </div>
          )
        }

        {!JOP && !collapsedMode && (
          <Breadcrumb currentLoc={location.pathname} />
        )}

        <div className={styles.side__right + " aligned"}>
          {!halfCollapsedMode &&
            selectedTerritory &&
            territories.length > 1 && (
              <Typography
                variant="body1"
                style={{ color: textColor, marginLeft: "2em" }}
                onClick={() => {
                  setSwitchTerritoryDialogOpen(true);
                }}
              >
                <FormattedMessage
                  id={"menu.current_territory"}
                  values={{
                    territory: selectedTerritory?.geography?.area_name,
                  }}
                />
              </Typography>
            )}

          {collapsedMode && <LanguageSelector textColor={textColor} />}

          {!collapsedMode && signUpAndSignInButtons}
        </div>

        {
          /* modal impersonate PMR */
          props.impersonateDialog && <ImpersonateDialog />
        }
      </nav>
    </header>
  );
}
