import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BookingValidation from "components/BookingValidation/BookingValidation.jsx";

import { changeBookingValidate } from "containers/ReservationForm/actions.js";
import {
  selectBookingByDatetime,
  removeProposal,
} from "containers/SearchForm/actions.js";
import {
  loadApplicableDiscounts,
  loadDiscountedPrices,
  resetPromoCode,
  resetTicketing,
  setTotalPrice,
} from "containers/Payment/actions";
import { validateBooking } from "./actions.js";

import { AuthModalsContext, AuthModal } from "contexts/AuthModalsContext";
import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";
import { getFormattedPassengersProfile } from "containers/PassengersProfiles/actions";
import { isPhoneNumberValidated } from "utils/newSmsFlow";

function BookingValidationContainer(props) {
  const {
    changeBookingValidate,
    edition,
    isRequesting,
    removeProposal,
    reservation,
    selectBooking,
    setTotalPrice,
    resetPromoCode,
    resetTicketing,
    validateBooking,
    isFixedLine,
    sessionDetails,
  } = props;

  let totalPrice;
  const dispatch = useDispatch();
  const { setPhoneCode, showAuthModal } = useContext(AuthModalsContext);
  const { selectedTerritory } = useContext(TerritoryContext);
  const { isAuthenticated, userProfile } = useContext(UserContext);

  const [checkingPhoneValidated, setCheckingPhoneValidated] = useState(false);

  const passengerProfiles = useSelector(
    (state) => state?.passengersProfiles?.profiles,
  );
  const selectedProposals = useSelector(
    (state) => state?.search?.selectedProposals,
  );
  const responses = useSelector((state) => state?.search?.responses);
  const promoCode = useSelector((state) => state?.payment?.promoCode);
  const isPromoCodeValid = useSelector(
    (state) => state?.payment?.isPromoCodeValid,
  );

  useEffect(() => {
    const territoryId = selectedTerritory?.territory_id;
    if (!passengerProfiles)
      dispatch(getFormattedPassengersProfile({ territoryId }));
  }, []);
  const navigate = useNavigate();

  const customerId = userProfile.customerId;

  const isMultiDateEnabled = selectedTerritory.booking?.multi_date?.enabled;
  const paymentOptions = selectedTerritory?.payment;

  const paymentMethods = useMemo(
    () => paymentOptions?.payment_methods || [],
    [paymentOptions?.payment_methods],
  );

  const availablePaymentMethods = useMemo(
    () =>
      paymentMethods.reduce((arr, mode) => {
        if (_.get(paymentOptions, `${mode}.enabled`, false)) {
          arr.push(mode);
        }

        return arr;
      }, []),
    [paymentOptions, paymentMethods],
  );

  if (_.isEmpty(selectedProposals)) {
    navigate("/search/result");

    return null;
  }

  // Payment is not supported with multiselection, so
  // ticketing is not supported with multiselection as well.
  // Therefore, we use the first selected proposal that is
  // actually the only one.
  const selectedProposalDateTime = Object.keys(selectedProposals)[0];
  const selectedProposalId = selectedProposals[selectedProposalDateTime];

  const discountOnSelectedProposal =
    responses[
      selectedProposalDateTime
    ]?.reservation_info?.proposed_datetimes.find(
      (proposal) => proposal.id === selectedProposalId,
    )?.discount || 0;

  if (!isFixedLine) {
    totalPrice = _.reduce(
      Object.keys(selectedProposals),
      (sum, datetime) => {
        const tempRide = responses[datetime];
        const tempBooking = _.get(
          tempRide,
          "reservation_info.proposed_datetimes",
          {},
        ).find((b) => b.id === selectedProposals[datetime]);

        return sum + _.get(tempBooking, "price", 0);
      },
      0,
    );
  }

  const isOnlyOnboard =
    (availablePaymentMethods.length === 1 &&
      availablePaymentMethods[0] === "onboard") ||
    isMultiDateEnabled;

  const onDelete = (id) => {
    const datetime = Object.keys(selectedProposals).find(
      (datetime) => selectedProposals[datetime] === id,
    );

    removeProposal(datetime);
  };

  dispatch(
    loadApplicableDiscounts({ selectedTripProposalId: selectedProposalId }),
  );

  dispatch(
    loadDiscountedPrices({
      selectedProposals,
      responses,
      promoCode,
      isPromoCodeValid,
    }),
  );

  const validate = async () => {
    if (!isRequesting) {
      if (!isAuthenticated) {
        showAuthModal(AuthModal.SignUp);
        return;
      }

      setCheckingPhoneValidated(true);
      const phoneNumberValidated = await isPhoneNumberValidated();
      setCheckingPhoneValidated(false);
      if (!phoneNumberValidated.validated) {
        setPhoneCode(phoneNumberValidated.phoneCode);
        showAuthModal(AuthModal.SignUpVerification);
        return;
      }

      if (edition)
        return changeBookingValidate(
          navigate,
          reservation.id,
          responses,
          selectedProposals,
          selectedTerritory.territory_key,
        );

      if (
        (!isFixedLine && totalPrice !== 0) ||
        discountOnSelectedProposal > 0
      ) {
        setTotalPrice(totalPrice);
        resetPromoCode();
        resetTicketing();
        navigate("/search/result/confirmation/payment");

        return null;
      }

      validateBooking(customerId, selectedTerritory.territory_key, "ZERO");
    }
  };

  return (
    <BookingValidation
      edition={edition}
      isOnlyOnboard={isOnlyOnboard}
      isRequesting={isRequesting || checkingPhoneValidated}
      onDelete={onDelete}
      responses={responses}
      selectBooking={selectBooking}
      selectedProposals={selectedProposals}
      totalPrice={!isFixedLine && totalPrice}
      validate={validate}
      isFixedLine={isFixedLine}
      sessionDetails={sessionDetails}
    />
  );
}

const _mapState = (state) => {
  return {
    isRequesting: state?.bookingValidation?.isRequesting,
    responses: state?.search?.responses,
    selectedProposals: state?.search?.selectedProposals,
    reservation: state?.reservation?.reservation,
    isFixedLine:
      state?.search?.selectedProposals?.[state?.search?.selectedDatetime]
        ?.sections !== undefined,
  };
};

const _mapDispatch = (dispatch) => ({
  validateBooking: (customerId, selectedTerritoryKey, paymentMode) => {
    dispatch(
      validateBooking({ customerId, paymentMode, selectedTerritoryKey }),
    );
  },

  changeBookingValidate: (
    navigate,
    selectedDays,
    responses,
    selectedProposals,
    selectedTerritoryKey,
  ) => {
    dispatch(
      changeBookingValidate(
        navigate,
        selectedDays,
        responses,
        selectedProposals,
        selectedTerritoryKey,
      ),
    );
  },

  removeProposal: (datetime) => {
    dispatch(removeProposal(datetime));
  },

  selectBooking: (datetime) => {
    dispatch(selectBookingByDatetime(datetime));
  },

  setTotalPrice: (price) => {
    dispatch(setTotalPrice(price));
  },
  resetPromoCode: () => {
    dispatch(resetPromoCode());
  },
  resetTicketing: () => {
    dispatch(resetTicketing());
  },
});

export default connect(_mapState, _mapDispatch)(BookingValidationContainer);
