import React, { ChangeEvent, useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";

import dayjs, { Dayjs } from "dayjs";

import { UserContext } from "contexts/UserContext";

import styles from "./JopSearchForm.cssmodule.scss";
import { JopLocation, JopSession } from "./JopTypes";
import jopdata from "./jopdata.json";
import { usePersistantState } from "./hooks";

export type TripDirection = "oneway" | "return";

const tripDirections: Record<TripDirection, React.ReactNode> = {
  oneway: <FormattedMessage id="jop2024.one_way" defaultMessage="Aller" />,
  return: <FormattedMessage id="jop2024.return" defaultMessage="Retour" />,
};

export enum TimeRestrictionType {
  departure = "DEPARTURE",
  arrival = "ARRIVAL",
}

export interface JopOd {
  origin: JopLocation;
  destination: JopLocation;
  timeRestrictionType: TimeRestrictionType;
  datetime: Dayjs;
}

export interface JopOdSectionProps {
  session: JopSession;
  onOdChange: (od: JopOd | undefined) => void;
  prefillReturnTrip: boolean;
}

type StationId = keyof typeof jopdata.stations;

export default function JopOdSection({
  session,
  onOdChange: onOdChange,
  prefillReturnTrip,
}: JopOdSectionProps) {
  const intl = useIntl();
  const { language } = useContext(UserContext);

  const [stationId, setStationId] = usePersistantState(
    "jopStationId",
    "",
    prefillReturnTrip,
  );
  const [tripDirection, setTripDirection] = usePersistantState<TripDirection>(
    "jopTripDirection",
    prefillReturnTrip ? "return" : "oneway",
    false,
  );
  const stations = useMemo(() => {
    if (session.siteCode === "BCY") {
      // Filter Bercy station out when site is Bercy
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { "Paris Bercy - Bourgogne Pays d\u2019Auvergne": _, ...stations } =
        jopdata.stations;
      return stations;
    }
    return jopdata.stations;
  }, [session.siteCode]);
  const day = useMemo(() => dayjs.tz(session.date), [session]);
  const departingFromStation = useMemo(
    () => tripDirection !== "return",
    [tripDirection],
  );

  useEffect(() => {
    if (!tripDirection || !stationId) {
      onOdChange(undefined);
      return;
    }
    const station = jopdata.stations[stationId];
    const site = jopdata.sites[session.siteCode];
    const startTimeParts = session.startTime.split(":").map(Number);
    const endTimeParts = session.endTime.split(":").map(Number);
    let od = departingFromStation
      ? {
          origin: station,
          destination: site,
          timeRestrictionType: TimeRestrictionType.arrival,
          datetime: day
            .set("hour", startTimeParts[0])
            .set("minute", startTimeParts[1])
            .subtract(30, "minute"),
        }
      : {
          origin: site,
          destination: station,
          timeRestrictionType: TimeRestrictionType.departure,
          datetime: day
            .set("hour", endTimeParts[0])
            .set("minute", endTimeParts[1])
            .add(30, "minute"),
        };
    if (
      ["Concorde 2-2bis-3, Seine", "Elysées", "Concorde 1-1bis-4"].includes(
        site.label.en,
      )
    ) {
      let datetime = day
        .set("hour", startTimeParts[0])
        .set("minute", startTimeParts[1])
        .subtract(120, "minute");
      if (departingFromStation === false) {
        datetime = datetime
          .set("hour", endTimeParts[0])
          .set("minute", endTimeParts[1])
          .subtract(30, "minute");
      }
      od = departingFromStation
        ? {
            origin: station,
            destination: site,
            timeRestrictionType: TimeRestrictionType.arrival,
            datetime: datetime,
          }
        : {
            origin: site,
            destination: station,
            timeRestrictionType: TimeRestrictionType.departure,
            datetime: datetime,
          };
    }
    onOdChange(od);
  }, [
    day,
    departingFromStation,
    onOdChange,
    session,
    stationId,
    tripDirection,
  ]);

  return (
    <section>
      <Typography variant="h2">
        <FormattedMessage
          id="jop2024.form_section_2"
          defaultMessage="2. Gare et direction"
        />
      </Typography>
      <div>
        <FormControl>
          <RadioGroup
            className={styles.marginBlockAuto}
            aria-label={intl.formatMessage({
              id: "jop2024.trip_direction",
              defaultMessage: "Direction du trajet",
            })}
            row
            value={tripDirection}
            onChange={(event) => {
              setTripDirection(event.target.value as unknown as TripDirection);
            }}
          >
            {Object.entries(tripDirections).map(
              ([tripDirectionId, tripDirectionLabel]) => (
                <FormControlLabel
                  key={tripDirectionId}
                  value={tripDirectionId}
                  control={<Radio />}
                  label={tripDirectionLabel}
                />
              ),
            )}
          </RadioGroup>
        </FormControl>
      </div>
      <div
        className={`${styles.odSectionFormControls} ${
          departingFromStation ? "" : styles.odSectionFormControls__reverse
        }`}
      >
        <FormControl>
          <InputLabel id="station-label">
            {departingFromStation ? (
              <FormattedMessage
                id="jop2024.departure_station"
                defaultMessage="Gare de départ"
              />
            ) : (
              <FormattedMessage
                id="jop2024.arrival_station"
                defaultMessage="Gare d'arrivée"
              />
            )}
          </InputLabel>
          <Select
            labelId="station-label"
            value={stationId}
            onChange={(event: ChangeEvent<{ value: StationId }>) =>
              setStationId(event.target.value)
            }
          >
            {Object.entries(stations)
              .sort((a, b) =>
                a[1].label[language].localeCompare(
                  b[1].label[language],
                  language,
                ),
              )
              .filter((station) => {
                if (session.siteCode === "ZNV") {
                  return [
                    "Paris Saint-Lazare",
                    "Paris Gare du Nord",
                    "Gare Rosa Parks",
                    "Paris Gare de l’Est",
                  ].includes(station[0]);
                }
                if (session.code === "Accès gratuit (free access) Seine") {
                  return [
                    "Paris Montparnasse",
                    "Paris Austerlitz",
                    "Paris Bercy - Bourgogne Pays d’Auvergne",
                    "Paris Gare de Lyon",
                  ].includes(station[0]);
                }
                return true;
              })
              .map(([stationId, station]) => (
                <MenuItem key={stationId} value={stationId}>
                  {station.label[language]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div className="column centered" style={{ gap: "0.3em" }}>
          <span>
            {departingFromStation ? (
              <FormattedMessage
                id="jop2024.arrival_site"
                defaultMessage="Site d'arrivée"
              />
            ) : (
              <FormattedMessage
                id="jop2024.departure_site"
                defaultMessage="Site de départ"
              />
            )}
          </span>
          <b>{jopdata.sites[session.siteCode].label[language]}</b>
        </div>
      </div>
    </section>
  );
}
