import React, { ChangeEvent, useEffect } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";

import styles from "./JopSearchForm.cssmodule.scss";
import { usePersistantState } from "./hooks";

export enum WheelchairType {
  manual = "manual",
  electric = "electric",
}

export enum MobilityAidType {
  walker = "walker",
  assistance_dog = "assistance_dog",
  crutches = "crutches",
  cane = "cane",
  white_cane = "white_cane",
}

const mobilityAidTypes = [
  {
    code: MobilityAidType.walker,
    label: "jop2024.walker",
  },
  {
    code: MobilityAidType.assistance_dog,
    label: "jop2024.assistance_dog",
  },
  {
    code: MobilityAidType.crutches,
    label: "jop2024.crutches",
  },
  {
    code: MobilityAidType.cane,
    label: "jop2024.cane",
  },
  {
    code: MobilityAidType.white_cane,
    label: "jop2024.white_cane",
  },
];

enum BooleanRadio {
  yes = "yes",
  no = "no",
}

export interface PassengersConfig {
  useWheelchair: boolean;
  wheelchairType: WheelchairType;
  needMobilityAid: boolean;
  mobilityAidType: MobilityAidType;
  escort: boolean;
  mobilityAids: string[];
}

export interface JopPassengersSectionProps {
  onPassengersConfigChange: (passengersConfig: PassengersConfig) => void;
  prefillReturnTrip: boolean;
}

export default function JopPassengersSection({
  onPassengersConfigChange,
  prefillReturnTrip,
}: JopPassengersSectionProps) {
  const intl = useIntl();

  const [useWheelchair, setUseWheelchair] = usePersistantState(
    "jopUseWheelchair",
    false,
    prefillReturnTrip,
  );

  const [needMobilityAid, setNeedMobilityAid] = usePersistantState(
    "jopNeedMobilityAid",
    false,
    prefillReturnTrip,
  );

  const [wheelchairType, setWheelchairType] = usePersistantState(
    "jopWheelchairType",
    WheelchairType.manual,
    prefillReturnTrip,
  );
  const [mobilityAidType, setMobilityAidType] = usePersistantState(
    "jopMobilityAidType",
    MobilityAidType.walker,
    prefillReturnTrip,
  );

  const [mobilityAids, setMobilityAids] = usePersistantState(
    "jopMobilityAids",
    [],
    prefillReturnTrip,
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMobilityAids(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const [escort, setEscort] = usePersistantState(
    "jopEscort",
    false,
    prefillReturnTrip,
  );

  useEffect(() => {
    onPassengersConfigChange({
      wheelchairType,
      escort,
      useWheelchair,
      mobilityAidType,
      needMobilityAid,
      mobilityAids,
    });
  }, [
    wheelchairType,
    escort,
    useWheelchair,
    mobilityAidType,
    needMobilityAid,
    mobilityAids,
  ]);

  return (
    <section>
      <Typography variant="h2">
        <FormattedMessage
          id="jop2024.form_section_3"
          defaultMessage="3. Passagers et équipements"
        />
      </Typography>
      <div className={styles.passengersFields}>
        <div>
          <Typography variant="body1">
            <FormattedMessage
              id="jop2024.use_wheelchair"
              defaultMessage="utilisez vous des fauteuils roulants ?"
            />
          </Typography>
        </div>

        <FormControl>
          <RadioGroup
            row
            value={useWheelchair ? BooleanRadio.yes : BooleanRadio.no}
            onChange={(event) => {
              setUseWheelchair(
                (event.target.value as unknown as BooleanRadio) ===
                  BooleanRadio.yes,
              );
            }}
          >
            <FormControlLabel
              value={BooleanRadio.no}
              control={<Radio />}
              label={<FormattedMessage id="jop2024.no" defaultMessage="non" />}
            />
            <FormControlLabel
              value={BooleanRadio.yes}
              control={<Radio />}
              label={<FormattedMessage id="jop2024.yes" defaultMessage="oui" />}
            />
          </RadioGroup>
        </FormControl>

        {useWheelchair && (
          <React.Fragment>
            <div>
              <Typography variant="body1">
                <FormattedMessage
                  id="jop2024.wheelchair_type"
                  defaultMessage="Type de fauteuil :"
                />
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage
                  id="jop2024.wheelchair_help"
                  defaultMessage="Si la largeur de votre fauteuil dépasse 90cm, veuillez sélectionner « volumineux »."
                />
              </Typography>
            </div>
            <FormControl>
              <RadioGroup
                row
                value={wheelchairType}
                onChange={(event) => {
                  setWheelchairType(
                    event.target.value as unknown as WheelchairType,
                  );
                }}
              >
                <FormControlLabel
                  value={WheelchairType.manual}
                  control={<Radio />}
                  label={
                    <FormattedMessage
                      id="jop2024.standard"
                      defaultMessage="standard"
                    />
                  }
                />
                <FormControlLabel
                  value={WheelchairType.electric}
                  control={<Radio />}
                  label={
                    <FormattedMessage
                      id="jop2024.bulky"
                      defaultMessage="volumineux"
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </React.Fragment>
        )}

        <div>
          <Typography variant="body1">
            <FormattedMessage
              id="jop2024.mobility_aid"
              defaultMessage="Avez-vous besoin d'un accompagnement pour monter et descendre du véhicule ?"
            />
          </Typography>
        </div>

        <FormControl>
          <RadioGroup
            row
            value={needMobilityAid ? BooleanRadio.yes : BooleanRadio.no}
            onChange={(event) => {
              setNeedMobilityAid(
                (event.target.value as unknown as BooleanRadio) ===
                  BooleanRadio.yes,
              );
            }}
          >
            <FormControlLabel
              value={BooleanRadio.no}
              control={<Radio />}
              label={<FormattedMessage id="jop2024.no" defaultMessage="non" />}
            />
            <FormControlLabel
              value={BooleanRadio.yes}
              control={<Radio />}
              label={<FormattedMessage id="jop2024.yes" defaultMessage="oui" />}
            />
          </RadioGroup>
        </FormControl>

        {needMobilityAid && (
          <React.Fragment>
            <div>
              <Typography variant="body1">
                <FormattedMessage
                  id="jop2024.mobility_aid_type"
                  defaultMessage="Type d'accompagnement :"
                />
              </Typography>
            </div>
            <FormControl>
              <Select
                labelId="mobility-aid-select-label"
                id="mobility-aid-select"
                multiple
                value={mobilityAids}
                onChange={handleChange}
              >
                {mobilityAidTypes.map((aid) => (
                  <MenuItem key={aid.code} value={aid.code}>
                    {intl.formatMessage({
                      id: aid.label,
                      defaultMessage: aid.label,
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </React.Fragment>
        )}

        <div>
          <Typography variant="body1">
            <FormattedMessage
              id="jop2024.do_you_have_an_escort"
              defaultMessage="Avez-vous un accompagnant ?"
            />
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id="jop2024.escort_help"
              defaultMessage="Un seul accompagnant autorisé par passager."
            />
          </Typography>
        </div>
        <FormControl>
          <RadioGroup
            aria-label={intl.formatMessage({
              id: "jop2024.escort",
              defaultMessage: "Accompagnant",
            })}
            row
            value={escort ? BooleanRadio.yes : BooleanRadio.no}
            onChange={(event) => {
              setEscort(
                (event.target.value as unknown as BooleanRadio) ===
                  BooleanRadio.yes,
              );
            }}
          >
            <FormControlLabel
              value={BooleanRadio.no}
              control={<Radio />}
              label={<FormattedMessage id="jop2024.no" defaultMessage="non" />}
            />
            <FormControlLabel
              value={BooleanRadio.yes}
              control={<Radio />}
              label={<FormattedMessage id="jop2024.yes" defaultMessage="oui" />}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </section>
  );
}
