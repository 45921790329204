import dayjs from "dayjs";
import api from "api";
import { isEmpty, pickBy, get } from "lodash";

import { snackMessage } from "containers/SnackBar/actions.js";

// use google maps api to retrieve geocodes from address... or padam backend
import { googleGeoCode } from "lib/autocomplete/googleGeoCode";
import { padamGeoCode } from "lib/autocomplete/padamGeoCode";
import { FORMAT_DATE, FORMAT_DATE_TIME, FORMAT_HOUR } from "utils/constants";

export const ON_FIELD_CHANGE = "@@search/ON_FIELD_CHANGE";
export const ACTUALIZE_DEPARTURE = "@@search/ACTUALIZE_DEPARTURE";
export const CHANGE_PASSENGERS = "@@search/CHANGE_PASSENGERS";
export const CHANGE_PERSONAL_ITEMS = "@@search/CHANGE_PERSONAL_ITEMS";
export const CHANGE_INDICATIONS_TO_DRIVER =
  "@@search/CHANGE_INDICATIONS_TO_DRIVER";
export const CLEAR_INDICATIONS_TO_DRIVER =
  "@@search/CLEAR_INDICATIONS_TO_DRIVER";
export const RECEIVE_NODES = "@@search/RECEIVE_NODES";
export const INITIATE_NODES_REQUEST = "@@search/INITIATE_NODES_REQUEST";
export const NODES_REQUEST_ERROR = "@@search/NODES_REQUEST_ERROR ";
export const PERSONAL_ITEMS_LOADING = "@@search/PERSONAL_ITEMS_LOADING";
export const ON_ADDRESS_SELECTED = "@@search/ON_ADDRESS_SELECTED";
export const UPDATE_COORDS = "@@search/UPDATE_COORDS";
export const NEW_RIDE_RESPONSE = "NEW_RIDE_RESPONSE";
export const INITIATE_MULTI_DATE_REQUEST = "INITIATE_MULTI_DATE_REQUEST";
export const INITIATE_REQUEST = "INITIATE_REQUEST";
export const INITIATE_REQUEST_FIXED_LINES = "INITIATE_REQUEST_FIXED_LINES";
export const NO_RIDE_RESPONSE = "NO_RIDE_RESPONSE";
export const END_REQUEST = "END_REQUEST";
export const END_REQUEST_FIXED_LINES = "END_REQUEST_FIXED_LINES";
export const ADD_PROPOSAL = "ADD_PROPOSAL";
export const ADD_FIXED_LINE_PROPOSAL = "ADD_FIXED_LINE_PROPOSAL";
export const REMOVE_PROPOSAL = "REMOVE_PROPOSAL";
export const REMOVE_FIXED_LINE_PROPOSAL = "REMOVE_FIXED_LINE_PROPOSAL";
export const SWITCH_SELECTED_BOOKING = "SWITCH_SELECTED_BOOKING";
export const ON_DAY_REMOVED = "ON_DAY_REMOVED";
export const FORM_ERRORS = "FORM_ERRORS";
export const SUBMITTING_FORM = "SUBMITTING_FORM";
export const ON_ADDRESS_SWAP = "ON_ADDRESS_SWAP";
export const SET_BOOKING_RETURN_TRIP = "SET_BOOKING_RETURN_TRIP";
export const RESET_SEARCH = "RESET_SEARCH";
export const RESET_SEARCH_BUT_PERSONAL_ITEMS =
  "RESET_SEARCH_BUT_PERSONAL_ITEMS";
export const RESET_SEARCH_FORM = "RESET_SEARCH_FORM";
export const LAST_BOOKINGS_FETCHED = "LAST_BOOKINGS_FETCHED";
export const RESET_PASSENGERS_COUNT = "RESET_PASSENGERS_COUNT";
export const FIXED_LINES_RESPONSE = "@@search/FIXED_LINES_RESPONSE";

/* also clean responses */
export const resetSearch = () => ({
  type: RESET_SEARCH,
});

export const resetSearchButPersonalItems = () => ({
  type: RESET_SEARCH_BUT_PERSONAL_ITEMS,
});

/**
 * Action triggered when the ASAP toggle is checked
 *
 * @return
 */
export const actualizeDeparture = () => {
  let datetimeNow = dayjs.tz().format(FORMAT_DATE_TIME);
  // in Cypress tests, we want to have a fixed datetime here
  if (window.Cypress) datetimeNow = dayjs(1582949766).format();

  return {
    type: ACTUALIZE_DEPARTURE,
    time: datetimeNow,
    timeRestrictionType: "departure",
    recurringOfferId: 0,
    selectedDays: [datetimeNow],
  };
};

/**
 * Action triggered when a user selects an address from the dropdown
 *
 * @param  string address
 * @param  string nodeId
 * @param  string latitude
 * @param  string longitude
 * @param  string addressType
 * @return
 */
export const onAddressSelected = (
  suggestion,
  nodeId,
  addressType,
  latitude,
  longitude,
  address,
  placeId,
) => ({
  type: ON_ADDRESS_SELECTED,
  suggestion,
  nodeId,
  addressType,
  latitude,
  longitude,
  address,
  placeId,
});

/**
 * Action triggered once addresses have been swapped
 */
export const onAddressSwap = () => ({ type: ON_ADDRESS_SWAP });

export const setBookingReturnTrip = (value) => ({
  type: SET_BOOKING_RETURN_TRIP,
  value,
});

/**
 * Action triggered when a user clicks on a favorite loader button
 *
 * @param  string favoriteType
 * @return
 */
export const loadFavorite = (favoriteType) => (dispatch, getState, getIntl) => {
  const favorite = getState().user.favorite;
  const intl = getIntl();

  // not really an error, favorite just not instanciated
  if (!favorite)
    return dispatch(
      snackMessage("info", intl.formatMessage({ id: "favorite.error" })),
    );
  const { home, office } = favorite;
  // onAddressSelected( display, nodeId, type, lat, lng, address). Favorites can only be address for now SO nodeId = null
  if (favoriteType === "home_to_workplace") {
    dispatch(
      onAddressSelected(
        home.address,
        null,
        "departure",
        home.latitude,
        home.longitude,
        home.address,
        null,
      ),
    );
    dispatch(
      onAddressSelected(
        office.address,
        null,
        "destination",
        office.latitude,
        office.longitude,
        office.address,
        null,
      ),
    );
  }

  if (favoriteType === "workplace_to_home") {
    dispatch(
      onAddressSelected(
        office.address,
        null,
        "departure",
        office.latitude,
        office.longitude,
        office.address,
        null,
      ),
    );
    dispatch(
      onAddressSelected(
        home.address,
        null,
        "destination",
        home.latitude,
        home.longitude,
        home.address,
        null,
      ),
    );
  }
};

/**
 * When the user see results of search, and select the ones he wants (tunnel 2/3)
 *
 * @param  date datetime
 * @param  id proposalId
 * @return object
 */
export const addProposal = (datetime, proposalId) => ({
  type: ADD_PROPOSAL,
  datetime: datetime,
  proposalId,
});

/**
 * When the user see results of fixed lines search, and select the ones he wants (tunnel 2/3)
 *
 * @param  date datetime
 * @param  object proposal
 * @return object
 */
export const addFixedLineProposal = (datetime, proposal) => ({
  type: ADD_FIXED_LINE_PROPOSAL,
  datetime: datetime,
  proposal: proposal,
});

/**
 * When the user removes a ride from selectedProposals (tunnel 2/3 and 3/3)
 * -> need to update selectedDatetime if removed
 * @param  date datetime
 * @return object
 */
export const removeProposal = (datetime) => ({
  type: REMOVE_PROPOSAL,
  datetime: datetime,
});

/**
 * When the user removes a ride from selectedProposals (tunnel 2/3 and 3/3)
 * -> need to update selectedDatetime if removed
 * @param  date datetime
 * @return object
 */
export const removeFixedLineProposal = (datetime) => {
  return {
    type: REMOVE_FIXED_LINE_PROPOSAL,
    datetime: datetime,
  };
};

/**
 * This action occurs in Booking Validation (tunnel 3/3) when the user switch between selectedProposals
 *
 * @param  string day
 * @param  string proposalIndex
 * @return object
 */
export const selectBookingByDatetime = (datetime) => ({
  type: SWITCH_SELECTED_BOOKING,
  datetime: datetime,
});

/**
 * This action occurs when the user removes a day either from the day picker
 * or the SearchResultRide component
 *
 * @param  string day
 * @return object
 */
export const onDayRemoved = (day) => ({
  type: ON_DAY_REMOVED,
  lookupKey: day,
});

/**
 * This action is triggered when a ride request has responded
 * (only used on multidate for now) ?
 * @param  object response
 * @param  string position [will act as a lookup key]
 * @return
 */
export const newRideResponse =
  (response, requestedDay) => (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: NEW_RIDE_RESPONSE,
      response,
      requestedDay,
    });
    // check if end of multidate request
    const daysRequesting = {
      ...state.search.daysRequesting,
      [requestedDay]: false,
    };
    if (Object.values(daysRequesting).every((b) => b === false)) {
      dispatch({
        type: END_REQUEST,
      });
    }
  };

/**
 * Action triggered when a ride request gives no result from the API
 *
 * @param  string requestedDay
 * @return object
 */
export const noRideResponse =
  (requestedDay, message, code, searchRequestId) => (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: NO_RIDE_RESPONSE,
      requestedDay,
      message,
      code,
      searchRequestId,
    });
    // check if end of multidate request
    const daysRequesting = {
      ...state.search.daysRequesting,
      [requestedDay]: false,
    };
    if (Object.values(daysRequesting).every((b) => !b)) {
      dispatch({
        type: END_REQUEST,
      });
    }
  };

/**
 * Function that will determine if proposals returned by api
 * are acceptable. To be acceptable they have to be in the range of
 * the requested time + 3 hours.
 *
 * @param  object response
 * @param  string requestedTime
 * @return boolean
 */
export const proposalsAreAcceptable = (response, requestedTime) => {
  const proposals = response.reservation_info.proposed_datetimes;
  const acceptable = [];

  requestedTime = new Date(requestedTime);

  for (let i = 0; i < proposals.length; i++) {
    const pickupTime = new Date(proposals[i].pickup_time);
    const difference = Math.abs(requestedTime - pickupTime) / (60 * 60 * 1000);

    if (difference <= 3) acceptable.push(proposals[i]);
  }

  if (!acceptable.length) return false;

  response.reservation_info.proposed_datetimes = acceptable;

  return true;
};

const sendRecurringRequest = (payload, formData, selectedTerritoryKey) =>
  function (dispatch) {
    const search = api.tripSearch;
    const { id, start_datetime, end_datetime } = formData.recurrence;
    payload = {
      ...payload,
      request_type: "recurring",
      time_data: {
        recurring_offer_id: id,
        start_datetime: createDateTime(start_datetime, formData.time),
        end_datetime: createDateTime(end_datetime, formData.time),
      },
    };
    search(payload, { territory: selectedTerritoryKey })
      .then((array) => {
        for (let i = 0; i < array.length; i++) {
          const response = array[i];
          const datetime = response.requested_datetime;
          if (response.error_code)
            dispatch(
              noRideResponse(
                datetime,
                response.detailed_error,
                response.error_code,
              ),
            );
          else {
            dispatch({
              type: NEW_RIDE_RESPONSE,
              response,
              requestedDay: datetime,
            });
          }
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {
        dispatch({ type: END_REQUEST });
      });
  };

/** utility function that creates datetime from day + hour
 *
 */
const createDateTime = (day, time) => {
  const formattedDay = dayjs.tz(day).format(FORMAT_DATE);
  const formattedTime = dayjs.tz(time).format(FORMAT_HOUR);
  const formattedDayTime = dayjs.tz(`${formattedDay} ${formattedTime}`);
  return dayjs.tz(formattedDayTime).format();
};

export const newFixedRideResponse = (response, requestedDay) => (dispatch) => {
  dispatch({
    type: FIXED_LINES_RESPONSE,
    response,
    requestedDay,
  });

  dispatch({
    type: END_REQUEST_FIXED_LINES,
  });
};

/**
 * sendRideRequest to MultiDate(payload,days)
 * => initiateRideRequest (state.daysRequesting) then FOR on requests
 *
 * @param  object payload
 * @param  array days
 * @return
 */
const sendMultiDateRequests =
  (payload, formData, selectedTerritoryKey, isFixedLinesEnabled) =>
  (dispatch) => {
    const search = api.new_search;
    // this applies formData.time (hour) to each day of formData.selectedDays
    const days = formData.selectedDays || [new Date()];

    // used to detect async ends of each request in order to correctly dispatch END_REQUEST
    dispatch(initiateMultiDateRequest(days));

    for (let i = 0; i < days.length; i++) {
      const paylo = { ...payload };
      paylo.datetime = createDateTime(days[i], formData.time);
      let searchRequestId = undefined;

      search(paylo, { territory: selectedTerritoryKey })
        .then((json) => {
          /**
           * Dispatch order here is important because proposalChange
           * reducer needs access to the response
           */
          if (json.reservation_info) {
            searchRequestId = json.reservation_info.id;
          }

          if (
            json.message /* && !proposalsAreAcceptable(json, payload.datetime) */
          ) {
            // IS IT USED ?
            return dispatch(noRideResponse(days[i], json.message, json.code));
          }

          return dispatch(newRideResponse(json, days[i]));
        })
        .catch((error) => {
          console.log({ error });
          const msg = _.get(error, "infos.detail.message");
          // msg doesn't exists <=> ERROR 500 (should we show more information ?)
          dispatch(
            noRideResponse(
              days[i],
              msg ||
                "An unknown error has occured, please try again later or contact your operator",
              _.get(error, "infos.detail.code"),
              error.infos.search_request?.[0],
            ),
          );
        })
        .then(() => {
          //Search fixed lines
          if (isFixedLinesEnabled && days.length === 1) {
            dispatch(initiateRequestFixedLines());

            const fixed_lines_payload = {
              departure_latitude: payload.departure_position.latitude,
              departure_longitude: payload.departure_position.longitude,
              destination_latitude: payload.destination_position.latitude,
              destination_longitude: payload.destination_position.longitude,
              departure_datetime: paylo.datetime.replace("+", "%2B"),
              territory: selectedTerritoryKey,
            };
            if (searchRequestId) {
              fixed_lines_payload.search_request = searchRequestId;
            }
            api
              .fixedLinesSearch({}, fixed_lines_payload)
              .then((json) => {
                return dispatch(newFixedRideResponse(json, days[i]));
              })
              .catch((error) => {
                console.log({ error });
                dispatch({
                  type: END_REQUEST_FIXED_LINES,
                });
              });
          }
        });
    }
  };

/**
 * Function used to send one ore several ride requests.
 *
 * @param  object selectedTerritory
 * @param  function dispatch
 * @param  function getState
 * @return
 */
const sendRideRequest = (selectedTerritory) =>
  function (dispatch, getState) {
    const state = getState();
    // const nodeIds = getState().search.nodeIds;
    const formData = state.search.searchForm;
    const profiles = state.passengersProfiles.profiles;
    const dep = formData.departure;
    const des = formData.destination;

    const standardSeats = Object.keys(profiles).reduce(
      (acc, profile) => acc + profiles[profile].count,
      0,
    );

    // rule : if [type] has nodeId, it is a node and we send display. Else we send address
    let payload = {
      departure_position: {
        address: dep.nodeId ? dep.display : dep.address,
        latitude: dep.latitude,
        longitude: dep.longitude,
      },
      destination_position: {
        address: des.nodeId ? des.display : des.address,
        latitude: des.latitude,
        longitude: des.longitude,
      },
      time_restriction_type: formData.timeRestrictionType.toUpperCase(),
      // historic cruft only for access
      standard_seats: standardSeats,
      custom_fields: formData.customFields,
    };
    // This object will hold the number of extra seats for each categories
    let categories = {};

    //* ******* adding extras seats
    const extras = selectedTerritory?.extras || {};
    // Handle special case for wheelchair (access category)
    const extrasTypes = _.keys(extras, []).filter(
      (type) =>
        _.get(extras[type], `max_${type}_seats_in_bus`) && type === "access",
    );
    extrasTypes.forEach((type) => {
      const count = _.get(formData, `passengers.${type}`);
      if (count) {
        if (type in categories) {
          categories[type] += count;
        } else {
          categories[type] = count;
        }
      }
    });

    // Compute number of extra seats by categories
    const personalItems =
      Object.values(formData?.personalItems?.personalItems) || [];
    personalItems
      .filter((personalItem) => personalItem.count > 0)
      .forEach((personalItem) => {
        // Loop over categories and accumulate required number of extra seats
        personalItem?.categories.forEach((category) => {
          const count = category.nb_seats * personalItem.count;
          if (category.name in categories && count) {
            categories[category.name] += count;
          } else {
            categories[category.name] = count;
          }
        });
      });

    // add extra for categories, following the search endpoint specifications
    Object.keys(categories).forEach((categoryKey) => {
      if (categories[categoryKey]) {
        payload = {
          ...payload,
          [`extras_${categoryKey}`]: {
            [`requested_${categoryKey}_seats`]: categories[categoryKey],
          },
        };
      }
    });

    dispatch(initiateRequest());
    if (state.search.searchForm.recurrence.id === 0)
      return dispatch(
        sendMultiDateRequests(
          payload,
          formData,
          selectedTerritory.territory_key,
          selectedTerritory?.booking?.fixed_lines?.enabled,
        ),
      );
    return dispatch(
      sendRecurringRequest(payload, formData, selectedTerritory.territory_key),
    );
  };

export const initiateRequest = () => ({ type: INITIATE_REQUEST });

export const initiateRequestFixedLines = () => ({
  type: INITIATE_REQUEST_FIXED_LINES,
});

/**
 * This action is triggered at the beginning of multiDate for( in days ) request
 * it creates an array of isRequesting for each day
 * in order to detect the end of rideRequest
 * @return
 */
export const initiateMultiDateRequest = (days) => {
  const daysRequesting = {};
  for (let i = 0; i < days.length; i++) daysRequesting[days[i]] = true;
  return {
    type: INITIATE_MULTI_DATE_REQUEST,
    daysRequesting,
  };
};

/**
 * Action triggered when a user clicks on a day from
 * our DayPicker component
 *
 * @param  string day
 * @param  boolean requestRide
 * @return
 */
export const onDayClick =
  (day, selectedTerritory) =>
  // DOOMED FUNCTION
  (dispatch, getState) => {
    /**
     * Do not mutate the original array
     */
    const state = getState();
    const formattedDay = dayjs(day).format(FORMAT_DATE);

    const multidate = selectedTerritory?.booking?.multi_date?.enabled;
    const previouslySelectedDays = state.search.searchForm.selectedDays;
    const selectedDays = previouslySelectedDays
      ? [...previouslySelectedDays]
      : [];

    if (selectedDays.length) {
      const matchedIndex = selectedDays.findIndex((selectedDay) => {
        const formattedSelectedDay = dayjs(selectedDay).format(FORMAT_DATE);
        return formattedDay === formattedSelectedDay;
      });

      if (!multidate) {
        selectedDays.shift();
      }

      if (matchedIndex !== -1) {
        selectedDays.splice(matchedIndex, 1);
        dispatch(onDayRemoved(formattedDay));
      } else selectedDays.push(formattedDay);
    } else selectedDays.push(formattedDay);

    if (multidate) {
      selectedDays.sort((a, b) => {
        return new Date(a) - new Date(b);
      });
    }

    /** Tell the store the selected days changed */
    dispatch(onFieldChange("selectedDays", selectedDays));
  };

/**
 * Action trigerred when form contains errors
 *
 * @param  object errors
 * @return object
 */
export const formErrors = (errors) => ({
  type: FORM_ERRORS,
  errors,
});

/**
 * Action used to reset all search state (errors too) EXCEPTED form data
 *
 * @return
 */
export const submittingForm = () => ({
  type: SUBMITTING_FORM,
});

/**
 * Function used to perform basic user input validation
 *  TO UPDATE
 * @param  object formData
 * @return object
 */
const validateUserInput = (formData, getIntl, customFieldInfos) => {
  const errors = {};
  const intl = getIntl();

  // departure
  if (formData.departure.display === "")
    errors.departure = intl.formatMessage({ id: "form.error.not_blank" });

  // destination
  if (formData.destination.display === "")
    errors.destination = intl.formatMessage({ id: "form.error.not_blank" });

  // *** CASE multidate
  if (formData.recurrence.id === 0 && formData.asap === false) {
    // selectedDays
    if (formData.selectedDays.length === 0)
      errors.selectedDays = intl.formatMessage({
        id: "form.error.select_date",
      });
    // hour
    else if (
      dayjs.tz(formData.selectedDays[0]).isSame(dayjs.tz(), "day") &&
      dayjs.tz(formData.time).add(2, "minutes").isBefore(dayjs.tz())
    )
      errors.time = intl.formatMessage({ id: "form.error.datetime_past" });
  }
  // *** CASE recurrence
  if (formData.recurrence.id !== 0) {
    const rec = formData.recurrence;
    // rec.start_datetime is before yesterday
    if (
      dayjs.tz(rec.start_datetime).isBefore(dayjs.tz()) &&
      !dayjs.tz(rec.start_datetime).isSame(dayjs.tz(), "day")
    )
      errors.recurrence.start_datetime = intl.formatMessage({
        id: "form.error.date_past",
      });

    // rec.end_datetime is before rec.start_datetime
    if (dayjs.tz(rec.end_datetime).isBefore(dayjs.tz(rec.start_datetime)))
      errors.recurrence.end_datetime = intl.formatMessage({
        id: "form.error.endstart",
      });

    // if start is today and hour is before now
    if (
      dayjs.tz(rec.start_datetime).isSame(dayjs.tz(), "day") &&
      dayjs.tz(formData.time).add(2, "minutes").isBefore(dayjs.tz())
    )
      errors.time = intl.formatMessage({ id: "form.error.datetime_past" });
  }
  // *** CASE customFields : check if "is required"
  Object.keys(customFieldInfos).map((name) => {
    const cf = customFieldInfos[name];
    const cfInForm = get(formData, "customFields." + name, null);
    if (cf.is_required && (!cfInForm || cfInForm === "")) {
      errors.customFields = {
        ...get(errors, "customFields", {}),
        [name]: intl.formatMessage({ id: "form.error.not_blank" }),
      };
    }
  });

  return errors;
};

const redirect = (navigate) => {
  window.scrollTo(0, 0);
  navigate("/search/result");
};

const isCoord = (string) => {
  // this is not perfect but enough
  return parseFloat(string) > -200 && parseFloat(string) < 200;
};

/**
 * Action triggered when a user submits the search form.
 * We send the request(s) and redirect right after.
 *
 * @param formData
 * @return
 */
export const submitSearchForm = (
  navigate,
  formData,
  selectedTerritory,
  productParameters,
) =>
  function (dispatch, getState, getIntl) {
    dispatch(submittingForm(formData.selectedDays || [new Date()]));
    const state = getState();

    const lat = selectedTerritory?.geography?.default_latitude;
    const lon = selectedTerritory?.geography?.default_longitude;

    // 1 check errors in form (field missing, etc)
    // we fetch the custom fields infos to know which is mandatory
    // TODO for now, consider only select type
    const customFieldInfos =
      selectedTerritory?.extras?.custom_fields?.search_request || {};
    const errors = validateUserInput(
      formData,
      getIntl,
      pickBy(customFieldInfos, (cf) => cf.type === "select"),
    );

    if (Object.keys(errors).length) {
      return dispatch(
        formErrors({
          ...state.search.formErrors,
          ...errors,
        }),
      );
    }
    // 2 if no address and display looks like coords
    for (const type of ["departure", "destination"]) {
      const coords = formData[type].display.split(",");
      if (coords.length === 2 && isCoord(coords[0]) && isCoord(coords[1])) {
        formData[type].address = formData[type].display;
        formData[type].latitude = coords[0];
        formData[type].longitude = coords[1];
        formData[type].coordsUpdated = true;
      }
    }
    // 2b if address already contains coords
    for (const type of ["departure", "destination"]) {
      const latitude = formData[type]?.latitude;
      const longitude = formData[type]?.longitude;

      if (latitude && longitude) {
        formData[type].latitude = latitude;
        formData[type].longitude = longitude;
        formData[type].coordsUpdated = true;
      }
    }

    // 3 if no address, try with display as address
    for (const type of ["departure", "destination"]) {
      if (isEmpty(formData[type].address)) {
        formData[type].address = formData[type].display;
      }
    }

    // 4 if not nodes and coords are not updated
    // update coords via googleGeoCode before sendRideRequest : more precision !
    const typesToUpdate = ["departure", "destination"].filter(
      (type) =>
        formData[type].address &&
        !formData[type].nodeId &&
        !formData[type].coordsUpdated,
    );

    // should add a better catch if geocode fails
    return Promise.all(
      typesToUpdate.map((type) => {
        if (productParameters?.features?.is_padam_geocoding_enabled) {
          return padamGeoCode({
            address: formData[type].address,
            placeId: formData[type].placeId,
            territory: selectedTerritory?.territory_key,
          });
        } else {
          return googleGeoCode({
            address: formData[type].address,
            lat,
            lon,
            placeId: formData[type].placeId,
          });
        }
      }),
    )
      .then((coordsUpdated) => {
        dispatch(updateCoordsIfNeeded(coordsUpdated, typesToUpdate));
      })
      .catch((error) =>
        console.log({
          error,
          details: "Contact IT Support about Google Maps API key (geocode)",
        }),
      )
      .finally(() => {
        dispatch(sendRideRequest(selectedTerritory, productParameters));
        redirect(navigate);
      });
  };

/**
 * Action triggered before sendRideRequest, to udpdate position.[coords] of non-Nodes with google geocode
 *
 * @param  array types
 * @param  array longitude
 * @return
 */
export const updateCoordsIfNeeded = (coordsUpdated, typesToUpdate) => ({
  type: UPDATE_COORDS,
  coordsUpdated,
  typesToUpdate,
});

/**
 * Fetch last bookings for OD suggestions
 *
 * @return
 */

export const fetchLastBookings = () => (dispatch, getState) => {
  const state = getState();
  const formData = _.get(state, "search.searchForm");
  api
    .getCustomerLastBookings()
    .then((json) => {
      const bookings = _.get(json, "results", []);
      if (bookings.length > 0) {
        // 1 - fill form if needed
        if (
          formData.departure.display === "" &&
          formData.destination.display === ""
        ) {
          const booking = bookings[0];
          dispatch(
            onAddressSelected(
              booking.pickup_node.name,
              booking.pickup_node.id,
              "departure",
              booking.pickup_node.position.latitude,
              booking.pickup_node.position.longitude,
            ),
          );
          dispatch(
            onAddressSelected(
              booking.dropoff_node.name,
              booking.dropoff_node.id,
              "destination",
              booking.dropoff_node.position.latitude,
              booking.dropoff_node.position.longitude,
            ),
          );
        }
        // 2 - load last bookings
        dispatch({
          type: LAST_BOOKINGS_FETCHED,
          bookings,
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

/**
 * Action triggered when a user changes a field form (including DayPicker)
 *
 * @param  string field
 * @param  mixed value
 * @return
 */
export const onFieldChange = (field, value) => {
  if (field === "time") {
    const now = new Date();

    if (!value) value = now;
  }
  if (field === "recurrence") if (!value) value = "once";

  return {
    type: ON_FIELD_CHANGE,
    field,
    value,
  };
};

export const fetchAllNodes = (selectedTerritory) => (dispatch) => {
  dispatch({ type: INITIATE_NODES_REQUEST });
  api
    .getNodeList(null, {
      search: "",
      territory: selectedTerritory?.territory_key,
      limit: 10000,
    })
    .then((json) => {
      dispatch({ type: RECEIVE_NODES, nodes: json.results });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: NODES_REQUEST_ERROR });
    });
  return;
};

export const getPersonalItems = (selectedTerritory) => (dispatch) => {
  const territoryId = selectedTerritory?.territory_id;
  if (territoryId) {
    dispatch(setPersonalItemsLoading(true));
    api
      .getOperatorPersonalItems({ territoryId })
      .then((json) => {
        const rawPersonalItems = json.results;

        const parsedPersonalItems = {};
        const categoriesRequests = [];
        const personalItemNames = [];
        for (const personalItem of rawPersonalItems) {
          parsedPersonalItems[personalItem.name] = {
            id: personalItem.id,
            name: personalItem.name,
            externalIdentifier: personalItem.external_identifier,
            count: 0,
            maximum: 5,
          };
          const itemId = personalItem.id;
          personalItemNames.push(personalItem.name);
          categoriesRequests.push(
            api.getOperatorPersonalItemCategories({
              territoryId,
              itemId,
            }),
          );
        }
        Promise.all([...categoriesRequests])
          .then((categories) => {
            categories.forEach((category, index) => {
              parsedPersonalItems[personalItemNames[index]].categories =
                category.results.map((result) => {
                  return {
                    id: result.id,
                    name: result.category_name,
                    nb_seats: result.nb_seats,
                  };
                });
            });
            // Compute maximum based on categories
            Object.values(parsedPersonalItems).forEach((personalItem) => {
              // Build an array with max number for each category associated with the personal item
              let categoriesMaximums = [];
              personalItem.categories.forEach((category) => {
                categoriesMaximums.push(
                  Math.floor(
                    getMaximumSeatsOfCategory(
                      category.name,
                      selectedTerritory?.extras,
                    ) / category.nb_seats,
                  ),
                );
              });
              // Maximum number of items in the minimum of these maximums
              if (categoriesMaximums.length > 0) {
                personalItem.maximum = Math.min(...categoriesMaximums);
              }
            });
            dispatch(changePersonalItems(parsedPersonalItems));
          })
          .catch(() => {
            dispatch(changePersonalItems({}));
          });
      })
      .catch((error) => {
        dispatch(changePersonalItems({}));
        console.log(`Error while fetching passenger personalitems: ${error}`);
      })
      .finally(() => {
        dispatch(setPersonalItemsLoading(false));
      });
  } else {
    dispatch(changePersonalItems({}));
  }
};

const getMaximumSeatsOfCategory = (category, extras) => {
  if (category in extras) {
    return extras[category][`max_${category}_seats_in_bus`] || 0;
  }
};

export const changePassengersCount = (passengerType, value) => ({
  type: CHANGE_PASSENGERS,
  passengerType,
  value,
});

export const changePersonalItems = (personalItems) => ({
  type: CHANGE_PERSONAL_ITEMS,
  personalItems,
});

export const setPersonalItemsLoading = (value) => ({
  type: PERSONAL_ITEMS_LOADING,
  value,
});

export const resetPassengersCount = () => ({
  type: RESET_PASSENGERS_COUNT,
});

export const searchReturnTrip =
  (departure, destination, time) => (dispatch) => {
    dispatch(
      onAddressSelected(
        departure.display,
        departure.nodeId,
        "destination",
        departure.latitude,
        departure.longitude,
        departure.address,
        null,
      ),
    );
    dispatch(
      onAddressSelected(
        destination.display,
        destination.nodeId,
        "departure",
        destination.latitude,
        destination.longitude,
        destination.address,
        null,
      ),
    );
    dispatch(onFieldChange("asap", false));
    dispatch(
      onFieldChange(
        "time",
        dayjs(time).add(1, "hour").format(FORMAT_DATE_TIME),
      ),
    );
  };

export const changeIndicationsToDriver = (indications) => ({
  type: CHANGE_INDICATIONS_TO_DRIVER,
  indicationsToDriver: indications,
});

export const clearIndicationsToDriver = () => ({
  type: CLEAR_INDICATIONS_TO_DRIVER,
});
