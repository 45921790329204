import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import MuiButton from "@material-ui/core/Button";

import { addProposal } from "containers/SearchForm/actions";

import ChoosedRideSummary from "components/organisms/ChoosedRideSummary/ChoosedRideSummary";
import PaperBox from "components/PaperBox/PaperBox";

import { useSearchResultConfirmStyles } from "./searchResultConfirmStyles";

function SearchResultConfirmContainer(props) {
  const classes = useSearchResultConfirmStyles();
  const {
    edition,
    reservation,
    responses,
    selectedProposals,
    personalItemsCount,
  } = props;

  const navigate = useNavigate();
  const params = useParams();

  const onConfirm = () => {
    const url = edition
      ? `/reservation/${params.id}/edit/confirm`
      : "/search/result/confirmation";
    window.scrollTo(0, 0);
    navigate(url);
  };

  if (_.isEmpty(selectedProposals)) {
    return <PaperBox title={<FormattedMessage id="search.confirm.notrip" />} />;
  }

  const title = (count) => {
    if (count === 1)
      return <FormattedMessage id="search.confirm.single_trip" />;
    else
      return (
        <span>
          {count + " "} <FormattedMessage id="search.confirm.title" />
        </span>
      );
  };

  return (
    <PaperBox title={title(Object.keys(selectedProposals).length)}>
      <ChoosedRideSummary
        edition={edition}
        reservation={reservation}
        responses={responses}
        selectedProposals={selectedProposals}
        personalItemsCount={personalItemsCount}
      />

      <MuiButton
        className={classes.confirmButton}
        color="primary"
        variant="contained"
        role="link"
        aria-label={props.intl.formatMessage({
          id: "aria.confirm_selected_trip",
        })}
        onClick={onConfirm}
        data-testid="search-result-confirm-btn"
      >
        {typeof selectedProposals[Object.keys(selectedProposals)[0]] ===
          "object" && Object.keys(selectedProposals).length === 1 ? (
          <FormattedMessage id="misc.see_details" />
        ) : (
          <FormattedMessage id="misc.next" defaultMessage="Continue" />
        )}
      </MuiButton>
    </PaperBox>
  );
}

function mapStateToProps(state) {
  const { selectedProposals } = state.search;

  return {
    selectedProposals: { ...selectedProposals },
    responses: state.search.responses,
    personalItemsCount: state?.search?.searchForm?.personalItems?.count,
    // in edit mode
    reservation: state.reservation.reservation,
  };
}

const mapDispatch = (dispatch) => ({
  addProposal: (day, proposalIndex) => {
    dispatch(addProposal(day, proposalIndex));
  },
});

export default connect(
  mapStateToProps,
  mapDispatch,
)(injectIntl(SearchResultConfirmContainer));
