import React from "react";
import { useIntl } from "react-intl";
import GoogleMapReact from "google-map-react";

import { makeStyles, useTheme, withTheme, Typography } from "@material-ui/core";

import BusMarker from "components/Icons/BusMarker";
import { DepartureIcon } from "icons/DepartureIcon";
import { ArrivalIcon } from "icons/ArrivalIcon";
import { getWalkingPath } from "lib/openstreetmap";

const busClasses = makeStyles({
  root: {
    width: "5rem",
    height: "5rem",
    // below is the custom "anchor point" of the resized svg
    position: "absolute",
    left: "-2.5rem",
    top: "-4rem",
  },
});

const walkClasses = makeStyles({
  root: {
    width: "2rem",
    height: "2rem",
    position: "absolute",
    left: "-1rem",
    top: "-1rem",
  },
});

function RideMap(props) {
  const intl = useIntl();
  const theme = useTheme();
  const walkClassName = walkClasses();

  const { dep, pickup, dropoff, des, defaultCenter, isFixedLine } = props;

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Return map bounds based on list of places
  const getMapBounds = (maps) => {
    const bounds = new maps.LatLngBounds();

    const extendBounds = ({ lat, lng }) => {
      bounds.extend(new maps.LatLng(lat, lng));
    };
    extendBounds(dep);
    extendBounds(pickup);
    extendBounds(des);
    extendBounds(dropoff);
    return bounds;
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = async (map, maps) => {
    // HERE we have access to the map/maps objects and to their original js api

    // ** 1 BOUNDS
    // Get bounds by our places
    const bounds = getMapBounds(maps);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);

    // ** 2 POLYLINES
    const Polyline = maps.Polyline;
    if (!_.isEqual(dep, pickup)) {
      // get value of getWalkingPath
      const path = await getWalkingPath(dep, pickup);
      new Polyline({
        path: path,
        strokeOpacity: 0,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4,
            },
            offset: "0",
            repeat: "20px",
          },
        ],
        map,
      });
    }
    // pickup to dropoff (bus)
    new Polyline({
      path: [pickup, dropoff],
      strokeWeight: 2,
      map,
    }); // dropoff to destination (walk)
    if (!_.isEqual(dropoff, des)) {
      const path = await getWalkingPath(dropoff, des);
      new Polyline({
        path: path,
        strokeOpacity: 0,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4,
            },
            offset: "0",
            repeat: "20px",
          },
        ],
        map,
      });
    }
  };

  if (typeof GOOGLE_MAPS_API_KEY === "undefined" || !GOOGLE_MAPS_API_KEY) {
    return (
      <Typography style={{ padding: "1rem" }} color="error">
        {"GOOGLE_MAPS_API_KEY is not defined"}
      </Typography>
    );
  }

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, libraries: ["places"] }}
      defaultCenter={defaultCenter}
      defaultZoom={10}
      onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
      title="map"
    >
      {/* Unfortunately, we can't group the conditional display because if we use an
      artifact, it will make the markers not display at the right coordinate on the map */}
      {isFixedLine ? (
        <DepartureIcon
          className={walkClassName.root}
          lat={pickup.lat}
          lng={pickup.lng}
          color={theme.palette.pickup.main}
          ariaLabel={intl.formatMessage({ id: "search.pickup.placeholder" })}
        />
      ) : (
        <BusMarker
          id="pickup"
          classes={busClasses()}
          lat={pickup.lat}
          lng={pickup.lng}
          color={theme.palette.pickup.main}
          ariaLabel={intl.formatMessage({ id: "search.pickup.placeholder" })}
        />
      )}
      {isFixedLine ? (
        <ArrivalIcon
          className={walkClassName.root}
          lat={dropoff.lat}
          lng={dropoff.lng}
          color={theme.palette.dropoff.main}
          ariaLabel={intl.formatMessage({ id: "search.dropoff.placeholder" })}
        />
      ) : (
        <BusMarker
          id="dropoff"
          classes={busClasses()}
          lat={dropoff.lat}
          lng={dropoff.lng}
          color={theme.palette.dropoff.main}
          ariaLabel={intl.formatMessage({ id: "search.dropoff.placeholder" })}
        />
      )}
    </GoogleMapReact>
  );
}

export default withTheme(RideMap);
