import React from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button, Paper, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import CancelRide from "containers/CancelRide/CancelRide";
import CircleCheck from "components/Icons/CircleCheck";

import { InfosBadges } from "molecules/InfosBadges/InfosBadges";

import { FORMAT_HOUR } from "utils/constants";

import { useRideSchedulesStyles } from "./rideSchedulesStyles";
import { PaymentMethodAPIEnum } from "types/payment";

export function RideSchedules(props) {
  const {
    canChangeSeats,
    edit,
    isDeletable,
    isEditable,
    isNotPast,
    isRecurring,
    hasUniversalLink = false,
    reservation,
    ride,
    setIsChangeSeatsDialogOpen,
    setIsUniversalLinkDialogOpen,
  } = props;

  const intl = useIntl();
  const classes = useRideSchedulesStyles();

  if (reservation.status === "CANCELED_BY_DRIVER") {
    return (
      <Paper className={classes.rideSchedulesContainer}>
        <InfosBadges hasBeenCanceledByDriver />

        <Typography>
          <FormattedMessage
            id="ride.canceled_by_driver_explanation"
            defaultMessage="Your reservation has been canceled by the driver, because he didn't find you at the starting point."
          />
        </Typography>
      </Paper>
    );
  }

  if (!ride) {
    return null;
  }

  let waitingTimeLabel;

  if (
    ride.reference_pickup_time &&
    ride.passenger_status !== PaymentMethodAPIEnum.ONBOARD
  ) {
    waitingTimeLabel = (
      <div className={`${classes.inlineLine} ${classes.confirmedTime}`}>
        <CircleCheck />
        <FormattedMessage id="ride_details.label.reference_pu_time_known" />
        <b>{dayjs(ride.reference_pickup_time).tz().format(FORMAT_HOUR)}</b>
      </div>
    );
  } else if (
    ride.passenger_status === PaymentMethodAPIEnum.ONBOARD &&
    ride.dropoff_remaining_time
  ) {
    waitingTimeLabel = (
      <FormattedMessage
        id="ride.remaining_time"
        values={{
          remainingTime: (
            <b>
              {dayjs
                .duration(ride.dropoff_remaining_time, "m")
                .asMinutes()
                .toFixed(0)
                .replace(/\b0\b/, "1")}
            </b>
          ),
        }}
      />
    );
  } else {
    waitingTimeLabel = null;
  }

  //const tripDuration = `${dayjs
  //  .duration({
  //    minutes: dayjs(reservation.dropoff_time).diff(
  //      dayjs(reservation.pickup_time),
  //      "minute"
  //    ),
  //  })
  //  .asMinutes()
  //  .toFixed(0)
  //  .replace(/\b0\b/, "1")} min`;
  const estimatedTripDuration = `≈ ${dayjs
    .duration({
      minutes: dayjs(reservation.dropoff_time)
        .round(10, "minute")
        .diff(dayjs(reservation.pickup_time).round(10, "minute"), "minute"),
    })
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min`;

  const walkDurationValue = dayjs.duration({
    minutes: reservation.pickup_walking_time + reservation.dropoff_walking_time,
  });
  const walkDuration = `${walkDurationValue
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min`;

  const personalItemsCount = reservation?.personalItems?.count || 0;
  return (
    <Paper className={classes.rideSchedulesContainer}>
      <div className={classes.flexLine}>
        <Typography variant="h2" className={classes.rideSchedulesHeader}>
          {dayjs(reservation.pickup_time).tz().format("dddd LL")}
        </Typography>
        <InfosBadges
          bikeSeats={reservation?.extras_bike?.requested_bike_seats || 0}
          duration={estimatedTripDuration}
          price={reservation?.payment?.amount}
          standardSeats={reservation.passengers_number || 0}
          wheelchairSeats={
            reservation?.extras_access?.requested_access_seats || 0
          }
        />
      </div>

      <div className={classes.flexLine}>
        <Typography variant="h3">{waitingTimeLabel}</Typography>
      </div>

      <div className={classes.flexLine}>
        {isDeletable && !isRecurring && (
          <CancelRide
            renderButton={(props) => (
              <Button
                variant="contained"
                size="small"
                color="primary"
                {...props}
              >
                <FormattedMessage id="misc.delete" />
              </Button>
            )}
          />
        )}

        {hasUniversalLink && isNotPast && (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={() => setIsUniversalLinkDialogOpen(true)}
          >
            <FormattedMessage id="action.buy_ticket" />
          </Button>
        )}
      </div>
    </Paper>
  );
}
