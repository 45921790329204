import * as React from "react";
import {
  createContext,
  useEffect,
  useMemo,
  useContext,
  useState,
  useCallback,
} from "react";

import * as Sentry from "@sentry/react";

import { resetSearch } from "containers/SearchForm/actions";

import { ProductContext } from "contexts/ProductContext";
import { UserContext } from "contexts/UserContext";

import { LocalTerritory } from "api/types/common";

import store from "store/store.js";

import { LOCAL_STORAGE_ITEM_TERRITORY_KEY } from "./constants";

type Territory = LocalTerritory;

interface TerritoryContextValue {
  territories: Territory[];
  selectedTerritory?: Territory;
  changeSelectedTerritory: (territoryKey: string) => void;
  switchTerritoryDialogOpen: boolean;
  setSwitchTerritoryDialogOpen: (switchTerritoryDialogOpen: boolean) => void;
}

export const TerritoryContext = createContext<TerritoryContextValue>(
  {} as TerritoryContextValue,
);

export const TerritoryContextProvider = ({ children }) => {
  const { commonTerritories } = useContext(ProductContext);
  const {
    onSavedTerritoryRestored,
    restoreSavedTerritory,
    savedTerritoryKey,
    setSelectedTerritoryKey: liftSelectedTerritoryKeyUpToUserContext,
    userTerritories,
    userProfileLoading,
    userProfileWillBeLoaded,
  } = useContext(UserContext);

  const [selectedTerritoryKey, setSelectedTerritoryKey] = useState<
    string | undefined
  >(window.localStorage.getItem(LOCAL_STORAGE_ITEM_TERRITORY_KEY) || undefined);
  const [switchTerritoryDialogOpen, setSwitchTerritoryDialogOpen] =
    useState(false);

  const territories = useMemo(
    () => (userTerritories.length > 0 ? userTerritories : commonTerritories),
    [commonTerritories, userTerritories],
  );

  const updateSelectedTerritoryKey = useCallback(
    (newSelectedTerritoryKey?: string) => {
      setSelectedTerritoryKey(newSelectedTerritoryKey);

      // When the selected territory changes, a series of actions
      // must be triggered.

      // Pass the selected territory up to the UserContext as some user
      // API calls need it.
      liftSelectedTerritoryKeyUpToUserContext(newSelectedTerritoryKey);

      // Cache the selected territory into the local storage
      if (newSelectedTerritoryKey) {
        window.localStorage.setItem(
          LOCAL_STORAGE_ITEM_TERRITORY_KEY,
          newSelectedTerritoryKey,
        );
      } else {
        window.localStorage.removeItem(LOCAL_STORAGE_ITEM_TERRITORY_KEY);
      }

      // Update the territory Sentry tag
      if (
        process.env.NODE_ENV === "production" &&
        process.env.SENTRY_PUBLIC_DSN
      ) {
        Sentry.configureScope((scope) => {
          scope.setTag("territory", newSelectedTerritoryKey);
        });
      }
    },
    [liftSelectedTerritoryKeyUpToUserContext],
  );

  useEffect(() => {
    if (restoreSavedTerritory && selectedTerritoryKey !== savedTerritoryKey) {
      store.dispatch(resetSearch());
      updateSelectedTerritoryKey(savedTerritoryKey);
    }
    onSavedTerritoryRestored();
  }, [
    onSavedTerritoryRestored,
    restoreSavedTerritory,
    savedTerritoryKey,
    selectedTerritoryKey,
    updateSelectedTerritoryKey,
  ]);

  useEffect(() => {
    // The territory selection dialog must be automatically opened if
    // the selected territory is not valid. This normally happens in
    // the following cases:
    //   - There is no selected territory yet;
    //   - The selected territory is not in the territory list.
    if (
      territories.length === 0 ||
      userProfileLoading ||
      userProfileWillBeLoaded
    )
      return;

    if (
      territories.length === 1 &&
      selectedTerritoryKey !== territories[0].territory_key
    ) {
      // Only one territory: select it automatically and exit
      store.dispatch(resetSearch());
      updateSelectedTerritoryKey(territories[0].territory_key);
      return;
    }
  }, [
    selectedTerritoryKey,
    territories,
    updateSelectedTerritoryKey,
    userProfileLoading,
    userProfileWillBeLoaded,
  ]);

  // Trigger popup off when found a valid territory is found
  useEffect(() => {
    if (territories.length === 0) return;

    // Check if the selected territory is in the territory list
    const selectedTerritoryIsValid = Boolean(
      territories.find(
        (territory) => territory.territory_key === selectedTerritoryKey,
      ),
    );

    if (selectedTerritoryIsValid) {
      setSwitchTerritoryDialogOpen(false);
    } else {
      // The selected territory is not in the territory list:
      store.dispatch(resetSearch());
      // Avoid override value that already set for deeplink
      if (selectedTerritoryKey !== undefined) {
        updateSelectedTerritoryKey(undefined);
      }
      setSwitchTerritoryDialogOpen(true);
    }
  }, [territories, selectedTerritoryKey, updateSelectedTerritoryKey]);

  // Allow the user to change the selected territory.
  const changeSelectedTerritory = useCallback(
    (territoryKey: string) => {
      // Search the territory in the territory list
      const newlySelectedTerritory = territories.find(
        (territory) => territory.territory_key === territoryKey,
      );

      if (!newlySelectedTerritory) {
        // Territory not found in the territory list: throw an error
        throw new Error(
          `Selected territory ${territoryKey} not found among known territories: ${territories.map(
            (t) => t.territory_key,
          )}`,
        );
      }

      // Territory found in the territory list: select it
      if (selectedTerritoryKey !== territoryKey) {
        store.dispatch(resetSearch());
        updateSelectedTerritoryKey(territoryKey);
      }
    },
    [territories, selectedTerritoryKey, updateSelectedTerritoryKey],
  );

  const selectedTerritory = useMemo(() => {
    const foundTerritory = territories.find(
      (territory) => territory.territory_key === selectedTerritoryKey,
    );
    if (
      foundTerritory &&
      foundTerritory.payment?.onboard?.info_url?.startsWith("http://")
    ) {
      foundTerritory.payment.onboard.info_url = `https://${foundTerritory?.payment?.onboard?.info_url.slice(7)}`;
    } else if (
      foundTerritory &&
      !foundTerritory.payment?.onboard?.info_url?.startsWith("https://")
    ) {
      foundTerritory.payment.onboard.info_url = `https://${foundTerritory?.payment?.onboard?.info_url}`;
    }
    return foundTerritory;
  }, [territories, selectedTerritoryKey]);

  const value = useMemo(
    () => ({
      territories,
      selectedTerritoryKey,
      selectedTerritory,
      changeSelectedTerritory,
      switchTerritoryDialogOpen,
      setSwitchTerritoryDialogOpen,
    }),
    [
      territories,
      selectedTerritoryKey,
      selectedTerritory,
      changeSelectedTerritory,
      switchTerritoryDialogOpen,
    ],
  );

  return (
    <TerritoryContext.Provider value={value}>
      {children}
    </TerritoryContext.Provider>
  );
};
